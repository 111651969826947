import ScheduleSection from '../ScheduleSection';
import ShiftSection from '../ShiftSection';

const ShiftSelectionMedium = () => {
    return (
        <div className="flex">
            <ScheduleSection />
            <ShiftSection />
        </div>
    )
}

export default ShiftSelectionMedium;
