import ShiftSummaryList from './components/ShiftSummaryList';
import styles from './index.module.scss';
import { ShiftScheduleGroup } from '../../../../models/Submission.model';
import { useContentLayoutContext } from '../../../../partials/ContentLayout/contexts/ContentLayout.context';
import { useEffect, useState } from 'react';
import { useSummaryContext } from '../../contexts/Summary.context';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../../../constants/Breakpoint.constant';
import { NumberUtil } from '../../../../utils/Number.util';

type SummarySectionProps = {
    selectedShiftScheduleGroups: ShiftScheduleGroup[]
}

const MD_SUMMARY_SECTION_PADDING_Y: number = 24 * 2;
const MD_RESULT_MESSAGE_MARGIN_BOTTOM: number = 16;
const SM_SUMMARY_SECTION_PADDING_Y: number = 16 * 2;

const SummarySection = ({selectedShiftScheduleGroups}: SummarySectionProps) => {
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const isSmScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.MD });
    const {bodyHeight: contentLayoutBodyHeight} = useContentLayoutContext();
    const {resultMessageHeight} = useSummaryContext();
    const [summarySectionHeight, setSummarySectionHeight] = useState<number>(0);

    useEffect(() => {
        if (!!contentLayoutBodyHeight) {
            if (isSmScreen) {
                setSummarySectionHeight(contentLayoutBodyHeight - (
                    SM_SUMMARY_SECTION_PADDING_Y
                    + resultMessageHeight + (!!resultMessageHeight ? MD_RESULT_MESSAGE_MARGIN_BOTTOM : 0)
                ));
            } else if (!isXsScreen) {
                setSummarySectionHeight(contentLayoutBodyHeight - (
                    MD_SUMMARY_SECTION_PADDING_Y
                    + resultMessageHeight + (!!resultMessageHeight ? MD_RESULT_MESSAGE_MARGIN_BOTTOM : 0)
                ));
            }
        }
    }, [isXsScreen, contentLayoutBodyHeight, resultMessageHeight]);

    return (
        <div style={{height: isXsScreen ? 'auto' : `${NumberUtil.convertPxToRem(summarySectionHeight)}rem`}}
             className={`print:!h-max mb-12 last:mb-0 sm:mb-0 overflow-y-auto pr-5 ${styles.summarySectionContent} sm:basis-[49%]`}>
            {selectedShiftScheduleGroups.map((shiftScheduleGroup: ShiftScheduleGroup) => (
                <ShiftSummaryList key={shiftScheduleGroup.schedule.scheduleId} shiftScheduleGroup={shiftScheduleGroup} />
            ))}
        </div>
    );
}

export default SummarySection;
