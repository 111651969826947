import axios from 'axios';
import { config } from '../configuration';

export const serviceInstance = axios.create({
    baseURL: config.REACT_APP_API_ENDPOINT,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

serviceInstance.interceptors.request.use(
    (_config: any) => {
        return {..._config, headers: {..._config.headers}};
    },
    (error: any) => {
        return Promise.reject(error);
    }
);
