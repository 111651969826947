const TileCheckIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12.5" r="12" fill="#03CE54"/>
            <path d="M6.5 13.5L10 17L18 9" stroke="white" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    );
}

export default TileCheckIcon;
