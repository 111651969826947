import ShiftCalendarIcon from '../../../../../../svgs/ShiftCalendarIcon';

const ShiftSelectionMessage = () => {
    return (
        <div className="h-full flex items-center justify-center flex-col">
            <div className="text-[1.3125rem] text-center mb-6 px-6">
                Select a day you can help on the left to see available shifts here
            </div>
            <ShiftCalendarIcon />
        </div>
    );
}

export default ShiftSelectionMessage;
