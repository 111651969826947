const generateRandomUuid = () => {
    let uuid, i, j;
    uuid = new Date().getTime().toString();
    for(j = 0; j < 19; j++) {
        i = Math.floor(Math.random()*16).toString(16).toUpperCase();
        uuid = uuid + i;
    }
    return uuid;
}

export const ObjectUtil = {
    generateRandomUuid,
}
