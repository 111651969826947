import { Player } from '@lottiefiles/react-lottie-player';
import enableLocationAnimation from './constants/enable-location-animation.json';

const LocationLoadingAnimation = () => {
    return (
        <div className="flex flex-col justify-center">
            <Player
                autoplay
                loop
                src={enableLocationAnimation}
                style={{ height: '18rem', width: '18rem' }} />
            <div className="font-extrabold text-center">
                Finding priority booths nearby...
            </div>
        </div>
    )
}

export default LocationLoadingAnimation;
