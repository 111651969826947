import { Drawer } from '@material-tailwind/react';
import { ReactNode, useState } from 'react';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/solid';
import { RightDrawerContext } from './contexts/RightDrawer.context';
import { useElementSize } from 'usehooks-ts';

type RightDrawerProps = {
    isOpened: boolean;
    setClose: () => void;
    title: string;
    children: ReactNode;
}

export const RIGHT_DRAWER_PADDING_TOP: number = 16;
export const RIGHT_DRAWER_HEADER_MARGIN_BOTTOM: number = 24;

const RightDrawer = ({isOpened, setClose, title, children}: RightDrawerProps) => {
    const [headerRef, { height: headerHeight }] = useElementSize();
    return (
        <RightDrawerContext.Provider value={{headerHeight}}>
            <Drawer
                placement="right"
                open={isOpened}
                onClose={setClose}
                size={340}
                className="px-4 py-4"
            >
                <div ref={headerRef} className="flex items-center mb-6">
                    <ArrowSmallLeftIcon className="h-5 w-5 font-thin" onClick={setClose} />
                    <div className="basis-full text-center font-medium">{title}</div>
                </div>
                {children}
            </Drawer>
        </RightDrawerContext.Provider>
    )
}

export default RightDrawer;
