import { createContext, useContext } from 'react';

export type LocationSelectionMobileProp = {
    saveLocationButtonHeight: number,
}

export const LocationSelectionMobileContext = createContext<LocationSelectionMobileProp>({
    saveLocationButtonHeight: 0,
})

export const useLocationSelectionMobileContext = () => useContext(LocationSelectionMobileContext);
