type BackButtonProps = {
    onClick: () => void;
}
const BackButton = ({onClick}: BackButtonProps) => {
    return (
      <button className="text-primary-orange font-extrabold uppercase text-sm" onClick={onClick}>Back</button>
    );
}

export default BackButton;
