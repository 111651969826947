import { SHIFT_ROLE_NAME, SHIFT_ROLE } from '../constants/Shift.constant';

const getRole = (scheduleRole: SHIFT_ROLE): SHIFT_ROLE_NAME => {
    switch (scheduleRole) {
        case SHIFT_ROLE.BOOTH_CAPTAIN:
            return SHIFT_ROLE_NAME.BOOTH_CAPTAIN;
        case SHIFT_ROLE.SCRUTINEER:
            return SHIFT_ROLE_NAME.SCRUTINEERING;
        default:
            return SHIFT_ROLE_NAME.VOLUNTEER;
    }
}

export const ShiftUtil = {
    getRole,
}
