import ShiftSelectionMessage from './components/ShiftSelectionMessage';
import { useShiftContext } from '../../contexts/Shift.context';
import ShiftList from '../ShiftList';
import { useContentLayoutContext } from '../../../../partials/ContentLayout/contexts/ContentLayout.context';
import { useEffect, useState } from 'react';
import { NumberUtil } from '../../../../utils/Number.util';
import { ShiftSectionContext } from './contexts/ShiftSection.context';
import { useElementSize } from 'usehooks-ts';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../../../constants/Breakpoint.constant';

const MD_SHIFT_SECTION_MARGIN_Y: number = 24 * 2;
const SM_SHIFT_SECTION_MARGIN_Y: number = 16 * 2;

const ShiftSection = () => {
    const {bodyHeight: contentLayoutBodyHeight} = useContentLayoutContext();
    const {currentDisplayedShifts} = useShiftContext();
    const [shiftSectionHeight, setShiftSectionHeight] = useState<number>(0);
    const [shiftSectionHeaderRef, { height: shiftSectionHeaderHeight }] = useElementSize();
    const isSmScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.MD });

    useEffect(() => {
        if (!!contentLayoutBodyHeight) {
            setShiftSectionHeight(contentLayoutBodyHeight - (isSmScreen ? SM_SHIFT_SECTION_MARGIN_Y : MD_SHIFT_SECTION_MARGIN_Y));
        }
    }, [contentLayoutBodyHeight]);

    return (
        <ShiftSectionContext.Provider value={{shiftSectionHeight, shiftSectionHeaderHeight}}>
            <div style={{height: `${NumberUtil.convertPxToRem(shiftSectionHeight)}rem`}} className="basis-[45%] my-4 md:my-6 ml-6 mr-10 overflow-y-auto border border-[#D5DADD] rounded">
                {currentDisplayedShifts?.length === 0 && (
                    <ShiftSelectionMessage />
                )}
                {currentDisplayedShifts?.length > 0 && (
                    <div className="p-6 md:px-6 md:pt-6 sm:pb-4 md:h-full">
                        <div ref={shiftSectionHeaderRef} className="font-medium mb-6">
                            Select your desired shifts
                        </div>
                        <ShiftList />
                    </div>
                )}
            </div>
        </ShiftSectionContext.Provider>
    );
}

export default ShiftSection;
