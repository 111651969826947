import { useSubmissionContext } from '../../contexts/Submission.context';
import { DAY_TYPE_ID } from '../../constants/DayTypeId.constant';

type StepInfoProps = {
    stepNumber: number;
}

const DEFAULT_TOTAL_STEPS = 4;
const DEFAULT_TOTAL_STEPS_WITHOUT_ELECTION_DAY = 3;

const StepInfo = ({stepNumber}: StepInfoProps) => {
    const { submission } = useSubmissionContext();
    const dayTypeId: DAY_TYPE_ID = submission?.dayTypeId ?? DAY_TYPE_ID.NONE;
    const totalSteps: number = dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? DEFAULT_TOTAL_STEPS_WITHOUT_ELECTION_DAY : DEFAULT_TOTAL_STEPS;

    return (
        <div className="uppercase text-primary-orange mb-4 text-sm lg:text-base">
            STEP {stepNumber} <span className="text-[#00344B]">&#8212; OF {totalSteps}</span>
        </div>
    );
}

export default StepInfo;
