import DialogLayout from '../DialogLayout';
import { ADDITIONAL_ROLE_ID } from '../../constants/AdditionalRoleId.constant';

type AdditionalRoleDialogProps = {
    additionalRoleId: ADDITIONAL_ROLE_ID;
    message: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onYesButtonClick: () => void;
    onNoButtonClick: () => void;
}

const AdditionalRoleDialog = (
    {
        additionalRoleId,
        message,
        isOpen,
        setOpen,
        onYesButtonClick,
        onNoButtonClick,
    }: AdditionalRoleDialogProps) => {
    const isAdditionalBoothCaptainRole = additionalRoleId === ADDITIONAL_ROLE_ID.BOOTH_CAPTAIN;
    const title = `Would you like to sign up as a '${isAdditionalBoothCaptainRole ? 'Booth Captain' : 'Scrutineer'}'?`;

    const handleYesButtonClick = () => {
        onYesButtonClick();
        setOpen(false);
    }

    const handleNoButtonClick = () => {
        onNoButtonClick();
        setOpen(false);
    }

    return (
        <DialogLayout title={title} isOpen={isOpen} setOpen={setOpen}>
            <div>
                <p className="px-4 text[#00344B] text-base">
                    {message}
                </p>
                <div>
                    <button className="w-full text-sm py-3 px-4 text-white bg-primary-orange font-extrabold mt-8 uppercase" onClick={handleYesButtonClick}>
                        {`YES, I ACCEPT ${isAdditionalBoothCaptainRole ? 'BOOTH CAPTAIN' : 'SCRUTINEERING'} ROLE`}
                    </button>
                    <button className="w-full text-sm py-3 px-4 font-extrabold border border-[#D5DADD] mt-2 uppercase text-[#002030]" onClick={handleNoButtonClick}>
                        NOT THIS TIME
                    </button>
                </div>
            </div>
        </DialogLayout>
    );
}

export default AdditionalRoleDialog;
