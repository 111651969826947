import React, { useState } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './partials/Layout';
import { SubmissionContext } from './contexts/Submission.context';
import { Submission } from './models/Submission.model';
import { ROUTE_PATH } from './constants/RoutePath.constant';
import LocationSelectionPage from './pages/LocationSelectionPage';
import BoothCaptainAgreementPage from './pages/BoothCaptainAgreementPage';
import ShiftSelectionPage from './pages/ShiftSelectionPage';
import SummaryPage from './pages/SummaryPage';
import WelcomePageV3 from './pages/WelcomePageV3';
import BoothCaptainSharedScheduleLayout from './partials/BoothCaptainSharedScheduleLayout';
import ImportantInformationPage from './pages/BoothCaptainSharedSchedulePage/components/ImportantInformationPage';
import RegisteredVolunteerPage from './pages/BoothCaptainSharedSchedulePage/components/RegisteredVolunteerPage';

function App() {
  const [submission, setSubmission] = useState<Submission>({} as Submission);
  // This code is included in email, without this code, user cant edit the booking shift
  const [submissionCode, setSubmissionCode] = useState("")

  // Keep current user shifts from previous submission, read only
  const [editingShifts, setEditingShifts] = useState<string[]>([])

  return (
      <SubmissionContext.Provider value= {{submission, setSubmission, submissionCode, setSubmissionCode, editingShifts, setEditingShifts}}>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Layout />}>
                      <Route path='' Component={() => {
                          window.location.href = 'https://supporterstack.com/';
                          return null;
                      }}/>
                      <Route path={`${ROUTE_PATH.WELCOME}/:nationBuilderId`} element={<WelcomePageV3 /> } />
                      <Route path={ROUTE_PATH.BOOTH_CAPTAIN_AGREEMENT} element={<BoothCaptainAgreementPage />} />
                      <Route path={ROUTE_PATH.LOCATION_SELECTION} element={<LocationSelectionPage />} />
                      <Route path={ROUTE_PATH.SHIFT_SELECTION} element={<ShiftSelectionPage />} />
                      <Route path={ROUTE_PATH.SUMMARY} element={<SummaryPage />} />
                  </Route>
                  {/*<Route path={ROUTE_PATH.SHARED_SCHEDULE} element={<BoothCaptainSharedScheduleLayout />}>*/}
                  {/*    <Route path='' element={<ImportantInformationPage />} />*/}
                  {/*    <Route path={ROUTE_PATH.REGISTERED_VOLUNTEERS} element={<RegisteredVolunteerPage />} />*/}
                  {/*</Route>*/}
              </Routes>
          </BrowserRouter>
      </SubmissionContext.Provider>
  );
}

export default App;
