import { Badge } from 'flowbite-react';
import { ReactNode } from 'react';

type TileBadgeProps = {
    isDisplayed: boolean;
    message: string | ReactNode;
    isDisabled?: boolean;
    className?: string;
    priorityClassName?: string;
}

const TileBadge = ({isDisplayed, message, isDisabled, className, priorityClassName}: TileBadgeProps) => {
    return (
        <Badge color="failure" size="sm" className={`absolute top-0 left-0 justify-center rounded-bl-none rounded-br-none w-full py-1 ${isDisplayed ? '' : 'invisible'} ${priorityClassName ? priorityClassName : `rounded-[0.45rem] text-xs uppercase ${isDisabled ? '!bg-[#D5DADD] !text-[#67777F]' : '!bg-[#FFD9D2] !text-primary-orange'} ${className}`}` }>
            {message}
        </Badge>
    );
}

export default TileBadge;
