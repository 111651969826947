import { ReactNode } from 'react';

type FooterProps = {
    containerRef?: any;
    children: ReactNode;
    className?: string;
}

const Footer = ({containerRef, children, className = ""}: FooterProps) => {
    return (
        <div ref={containerRef} className={`fixed bg-white bottom-0 w-full border-t border-[#D5DADD] px-4 md:px-10 py-3 md:py-6 md:flex md:justify-end ${className}`}>
            {children}
        </div>
    );
}

export default Footer;
