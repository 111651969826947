import { useSubmissionContext } from '../../contexts/Submission.context';
import SummarySection from './components/SummarySection';
import SummarySuccessTile from './components/SummarySuccessTile';
import NextButton from '../../partials/NextButton';
import { ShiftScheduleGroup, Submission, SubmissionShifts } from '../../models/Submission.model';
import { DAY_TYPE_ID } from '../../constants/DayTypeId.constant';
import {
    CustomShiftSubmissionDTO,
    ScheduleSubmissionDTO,
    StandardShiftSubmissionDTO,
    SubmissionDTO
} from '../../models/dto/SubmissionDTO.model';
import { Shift } from '../../models/Shift.model';
import { ScheduleService } from '../../services/Schedule.service';
import { useEffect, useRef, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import SummaryErrorTile from './components/SummaryErrorTile';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/RoutePath.constant';
import ContentLayout from '../../partials/ContentLayout';
import { LocationInfo } from '../../models/LocationInfo.model';
import { Spinner } from 'flowbite-react';
import { LocalStorageUtil } from '../../utils/LocalStorage.util';
import { Schedule } from '../../models/Schedule.model';
import { StoredShift } from '../../models/StoredShift.model';
import { ArrayUtil } from '../../utils/Array.util';
import RemoveShiftsConfirmationDialog from "../../partials/RemoveShiftsConfirmationDialog";
import { useReactToPrint } from 'react-to-print';
import { useElementSize } from 'usehooks-ts';
import { SummaryContext } from './contexts/Summary.context';

const buildConfirmButtonText = (isSubmitting: boolean) => {
    return isSubmitting ? (<Spinner size="sm" aria-label="Submission is in progress" />) : 'Confirm';
}

const checkSelectedShiftsExist = (shiftScheduleGroups: ShiftScheduleGroup[]): boolean => {
    if (shiftScheduleGroups?.length > 0) {
        for (let i = 0; i < shiftScheduleGroups.length; i ++) {
            if (shiftScheduleGroups[i]?.standardShifts?.length > 0 || shiftScheduleGroups[i]?.customShifts?.length) {
                return true;
            }
        }
    }
    return false;
}

const filterShifts = (eventType: DAY_TYPE_ID, submission: Submission): ShiftScheduleGroup[] => {
    const selectedShifts = submission?.selectedSubmissionShifts;
    if (selectedShifts) {
        return ((Object.values(selectedShifts) ?? []) as ShiftScheduleGroup[]).filter(
            (shiftScheduleGroup: ShiftScheduleGroup) => shiftScheduleGroup.eventType === eventType
        );
    }
    return [];
}

const mapSubmissionShifts = (schedules: Schedule[]): SubmissionShifts => {
    const submissionShifts: SubmissionShifts = {};
    schedules.forEach((schedule: Schedule) => {
        submissionShifts[schedule.scheduleId] = {
            eventType: schedule?.eventType ?? DAY_TYPE_ID.NONE,
            schedule,
            standardShifts: mapShifts([...(schedule?.standardShifts ?? [])], schedule.scheduleId),
            customShifts: mapShifts([...(schedule?.customShifts ?? [])], schedule.scheduleId),
        }
    });
    return submissionShifts;
}

const mapShifts = (storedShifts: StoredShift[], scheduleId: string) => {
    return storedShifts.filter((shift)=>shift).map((storedShift: StoredShift) => {
        return {
            id: storedShift._id,
            role: storedShift.shift_role,
            type: storedShift.shift_type,
            isAvailable: true,
            startTime: storedShift.startTime ?? storedShift.start_time,
            endTime: storedShift.endTime ?? storedShift.end_time,
            scheduleId,
        } as Shift;
    })
}

const isShiftsOfTwoSubmissionShiftsEqual = (submissionShifts1: SubmissionShifts, submissionShifts2: SubmissionShifts): boolean => {
    const submissionShifts1Keys: string[] = Object.keys(submissionShifts1);
    if (submissionShifts1Keys?.length !== Object.keys(submissionShifts2)?.length) {
        return false;
    } else {
        for (let i = 0; i < submissionShifts1Keys.length; i++) {
            const scheduleId: string = submissionShifts1Keys[i];
            const submissionShifts1StandardShifts: Shift[] = submissionShifts1[scheduleId]?.standardShifts ?? [];
            const submissionShifts1CustomShifts: Shift[] = submissionShifts1[scheduleId]?.customShifts ?? [];
            const submissionShifts2StandardShifts: Shift[] = submissionShifts2[scheduleId]?.standardShifts ?? [];
            const submissionShifts2CustomShifts: Shift[] = submissionShifts2[scheduleId]?.customShifts ?? [];
            if (!ArrayUtil.isArrayDeepEqual(submissionShifts1StandardShifts, submissionShifts2StandardShifts)
                || !ArrayUtil.isArrayDeepEqual(submissionShifts1CustomShifts, submissionShifts2CustomShifts)) {
                return false;
            }
        }
    }
    return true;
}

const isAtLeastOneShiftSelectedInShiftScheduleGroups = (shiftScheduleGroups: ShiftScheduleGroup[]): boolean => {
    if (shiftScheduleGroups?.length > 0) {
        for (let i = 0; i < shiftScheduleGroups.length; i++) {
            const shiftScheduleGroup = shiftScheduleGroups[i];
            if (shiftScheduleGroup?.standardShifts?.length > 0 || shiftScheduleGroup?.customShifts?.length > 0) {
                return true;
            }
        }
    }
    return false;
}

const SummaryPage = () => {
    const { submission, setSubmission, submissionCode } = useSubmissionContext();
    const [isSubmittedSuccess, setSubmittedSuccess] = useState<boolean>(false);
    const [isErrorMessageDisplayed, setErrorMessageDisplayed] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState(false);
    const [searchParams] = useSearchParams();
    const isReviewing: boolean = searchParams.get('isReviewing') === 'true';
    const [selectedPrePollingShiftScheduleGroups, setSelectedPrePollingShiftScheduleGroups] = useState<ShiftScheduleGroup[]>([]);
    const [selectedElectionShiftScheduleGroups, setSelectedElectionShiftScheduleGroups] = useState<ShiftScheduleGroup[]>([]);
    const [isConfirmButtonDisplayed, setConfirmButtonDisplayed] = useState<boolean>(false);
    const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = useState(false);
    const printContainerRef = useRef();
    const [resultMessageRef, { height: resultMessageHeight }] = useElementSize();
    const resultContainerRef = useRef();

    useEffect(() => {
        let updatedSubmission: Submission = LocalStorageUtil.getStoredSubmission();
        if (!updatedSubmission?.nationBuilderId && !updatedSubmission?.nationbuilder_id) {
            updatedSubmission = {...submission};
        }
        if (isReviewing) {
            updatedSubmission.selectedSubmissionShifts = mapSubmissionShifts(updatedSubmission?.schedules ?? []);
        }
        LocalStorageUtil.setStoredSubmission(updatedSubmission);
        setSubmission(updatedSubmission);
    }, []);

    useEffect(() => {
        setSelectedPrePollingShiftScheduleGroups(filterShifts(DAY_TYPE_ID.PRE_POLLING_DAYS, submission));
        setSelectedElectionShiftScheduleGroups(filterShifts(DAY_TYPE_ID.ELECTION_DAY, submission));
        setConfirmButtonDisplayed(checkConfirmButtonDisplayed(submission));
    }, [submission]);

    useEffect(() => {
        if (isErrorMessageDisplayed || (!isErrorMessageDisplayed && isSubmittedSuccess)) {
            (resultContainerRef.current as any)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [isErrorMessageDisplayed, isSubmittedSuccess])

    const handleConfirmButtonClick = () => {
        if (!!submission?.nationBuilderId || !!submission?.nationbuilder_id) {
            const scheduleSubmissionDTOs: ScheduleSubmissionDTO[] = [];
            const submissionShifts: SubmissionShifts = submission.selectedSubmissionShifts ?? {};
            for(let scheduleId in submissionShifts) {
                const eventType: DAY_TYPE_ID = submissionShifts[scheduleId]?.eventType ?? DAY_TYPE_ID.NONE;
                const locationInfo = eventType === DAY_TYPE_ID.PRE_POLLING_DAYS
                    ? submission?.selectedPrePollingLocationInfo
                    : submission?.selectedElectionLocationInfo;
                const shiftScheduleGroup: ShiftScheduleGroup = submissionShifts[scheduleId];
                if (shiftScheduleGroup?.standardShifts?.length > 0 || shiftScheduleGroup?.customShifts?.length > 0) {
                    scheduleSubmissionDTOs.push({
                        scheduleId,
                        eventID: locationInfo?.eventId ?? '',
                        eventType,
                        locationId: locationInfo?.locationID ?? '',
                        standardShifts: shiftScheduleGroup?.standardShifts?.map((shift: Shift) => {
                            return {shiftId: shift.id} as StandardShiftSubmissionDTO;
                        }) ?? [],
                        customShifts: shiftScheduleGroup?.customShifts?.map((shift: Shift) => {
                            return {
                                startTime: shift.startTime,
                                endTime: shift.endTime,
                            } as CustomShiftSubmissionDTO;
                        }) ?? [],
                    });
                }
            }
            const submissionDTO: SubmissionDTO = {
                nation_id: submission?.nationBuilderId ?? submission?.nationbuilder_id ?? '',
                selectedDayTypeId: submission?.dayTypeId ?? submission?.selectedDayTypeId ?? DAY_TYPE_ID.NONE,
                // userLongitude: submission?.userLongitude ?? DEFAULT_NUMBER.LONGITUDE,
                // userLatitude: submission?.userLatitude ?? DEFAULT_NUMBER.LATITUDE,
                isBoothCaptainAccepted: !!submission?.isBoothCaptainAccepted,
                selectedPrePollingLocationInfo: isAtLeastOneShiftSelectedInShiftScheduleGroups(selectedPrePollingShiftScheduleGroups) ? submission?.selectedPrePollingLocationInfo : null,
                selectedElectionLocationInfo: isAtLeastOneShiftSelectedInShiftScheduleGroups(selectedElectionShiftScheduleGroups) ? submission?.selectedElectionLocationInfo : null,
                schedules: scheduleSubmissionDTOs,
                edit: !!submission._id,
                submissionCode: submissionCode || '',
            };
            setSubmitting(true);
            ScheduleService.postSubmission(submissionDTO).then((response: any) => {
                setSubmitting(false);
                setSubmitted(true)
                if (response?.status === StatusCodes.OK) {
                    setSubmittedSuccess(true);
                }
            }).catch((error: any) => {
                setSubmitting(false);
                setErrorMessageDisplayed(true);
                console.error('SummaryPage > ScheduleService.postSubmission has an error', error);
            });
        }
    }

    const handleRemoveAllShifts = () => {
        setSubmitting(true);
        ScheduleService.removeAllShifts(
            submission?.nationBuilderId ?? submission?.nationbuilder_id ?? '',
            submissionCode,
        ).then((response: any) => {
            setSubmitting(false);
            setSubmitted(true);
            // Remove submission from local storage, navigate to initial page
            if (response?.status === StatusCodes.OK) {
                setSubmission({});
                window.location.href = 'https://supporterstack.com/';
            }
        }).catch((error: any) => {
            setSubmitting(false);
            setErrorMessageDisplayed(true);
            console.error('SummaryPage > handleRemoveAllShifts has an error', error);
        });
    }

    const handleEditButtonClick = () => {
        navigate(`/${ROUTE_PATH.LOCATION_SELECTION}?isEditing=true`);
    }

    const handleNextButtonClick = () => {
        window.parent.location.href = 'https://supporterstack.com/';
    }

    const checkConfirmButtonDisplayed = (submission: Submission) => {
        return (submission?.storedSchedules?.length ?? 0) === 0
            || ((submission?.storedSchedules?.length ?? 0) > 0
                && !isShiftsOfTwoSubmissionShiftsEqual(submission?.selectedSubmissionShifts ?? {}, mapSubmissionShifts(submission?.storedSchedules ?? [])));
    }

    const handlePrint = useReactToPrint({
        content: () => printContainerRef.current as any,
    });

    return (
        <SummaryContext.Provider value={{resultMessageHeight}}>
            <ContentLayout title="Summary" footerClass={isSubmittedSuccess ? 'hidden' : ''} footerContent={(
                <div className={`flex justify-between md:justify-end`}>
                    {isSubmittedSuccess && (
                        <>
                            <button className="basis-[49%] font-extrabold uppercase text-sm p-3 border border-[#D5DADD] bg-white md:basis-auto md:mr-2"
                                    onClick={handlePrint}>
                                Print/Save PDF
                            </button>
                            <NextButton title="Next" onClick={handleNextButtonClick} />
                        </>
                    )}
                    {!isSubmittedSuccess && (
                        <>
                            {isConfirmButtonDisplayed && (
                                <NextButton title={buildConfirmButtonText(isSubmitting)} isDisable={isSubmitting} onClick={handleConfirmButtonClick} />
                            )}
                            {submissionCode && (
                                <button type="button"
                                        className={`basis-[49%] font-extrabold uppercase text-sm p-3 border border-[#D5DADD] bg-white md:basis-auto md:ml-2 justify-between float-right flex items-center`}
                                        onClick={()=>{
                                            setShowConfirmRemoveDialog(true)
                                        }}>
                                    <div className="min-w-[4.25rem]">
                                        {isSubmitting ? (<Spinner size="sm" aria-label="Cancel is in progress" />) : 'Cancel All Shifts'}
                                    </div>
                                </button>
                            )}
                            {((submission._id && submissionCode) || (!submission._id)) && <button className="basis-[49%] font-extrabold uppercase text-sm p-3 border border-[#D5DADD] bg-white md:basis-auto md:ml-2"
                                                                                                  onClick={handleEditButtonClick}>
                                Edit
                            </button>}
                        </>
                    )}
                </div>
            )}>
                <RemoveShiftsConfirmationDialog
                    message="Are you sure you want to cancel all your shifts?"
                    isOpen={showConfirmRemoveDialog}
                    setOpen={(value)=>{setShowConfirmRemoveDialog(value)}}
                    onYesButtonClick={handleRemoveAllShifts}
                    onNoButtonClick={() => {
                    }} />
                <div>
                    <div className="p-4 md:py-6 md:px-10 sm:flex sm:justify-between" ref={printContainerRef as any}>
                        {checkSelectedShiftsExist(selectedElectionShiftScheduleGroups) && (<SummarySection selectedShiftScheduleGroups={selectedElectionShiftScheduleGroups} />)}
                        {checkSelectedShiftsExist(selectedPrePollingShiftScheduleGroups) && (<SummarySection selectedShiftScheduleGroups={selectedPrePollingShiftScheduleGroups} />)}
                    </div>
                    <div ref={resultContainerRef as any}>
                        <div ref={resultMessageRef}>
                            {(!isErrorMessageDisplayed && isSubmittedSuccess) && (
                                <div>
                                    <SummarySuccessTile />
                                    {/*add Print/Save PDF and Next buttons for mobile*/}
                                    <div className="flex flex-col mx-4 mb-4 md:hidden">
                                        <button className="w-full font-extrabold uppercase text-sm p-3 border border-[#D5DADD] bg-white"
                                                onClick={handlePrint}>
                                            Print/Save PDF
                                        </button>
                                    </div>
                                    <div className="flex flex-col mx-4 mb-4 md:hidden">
                                        <NextButton title="Next" onClick={handleNextButtonClick} />
                                    </div>
                                </div>
                            )}
                            {isErrorMessageDisplayed && (<SummaryErrorTile />)}
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </SummaryContext.Provider>
    );
}

export default SummaryPage;
