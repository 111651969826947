import { createContext, useContext } from 'react';

export type SummaryContextProp = {
    resultMessageHeight: number,
}

export const SummaryContext = createContext<SummaryContextProp>({
    resultMessageHeight: 0,
})

export const useSummaryContext = () => useContext(SummaryContext);
