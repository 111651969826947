import { Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useLocationContext } from '../../../../../../contexts/Location.context';
import { DAY_TYPE_ID } from '../../../../../../../../constants/DayTypeId.constant';
import TileCheckIcon from '../../../../../../../../svgs/TileCheckIcon';
import TileUncheckIcon from '../../../../../../../../svgs/TileUncheckIcon';
import { LocationAvailable } from '../../../../../../../../models/LocationAvailable.model';
import { LocationInfo } from '../../../../../../../../models/LocationInfo.model';
import { LocationUtil } from '../../../../../../../../utils/Location.util';
import TileBadge from '../../../../../../../../partials/TileBadge';
import { useSubmissionContext } from '../../../../../../../../contexts/Submission.context';

type LocationTileProps = {
    containerRef?: any;
    locationInfo: LocationInfo;
    className?: string;
}

const LocationTile = ({containerRef, locationInfo, className}: LocationTileProps) => {
    const {
        selectedPrePollingLocationId,
        setSelectedPrePollingLocationId,
        selectedElectionLocationId,
        setSelectedElectionLocationId,
    } = useLocationContext();
    const [isLocationSelected, setLocationSelected] = useState<boolean>(false);
    const locationAvailable: LocationAvailable = locationInfo?.locationAvail;
    const dayTypeId: DAY_TYPE_ID = locationInfo?.eventType ?? DAY_TYPE_ID.NONE;
    const isPrePollingDayType: boolean = dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS;
    const isBoothCaptainAvailable: boolean = !!locationAvailable?.isBoothCaptainAvailable;
    const isScrutineeringAvailable: boolean = !!locationAvailable?.isScrutineeringAvailable;
    const isShiftAvailable: boolean = !!locationAvailable?.isShiftAvailable;
    const {submission} = useSubmissionContext();
    const isBoothCaptainAccepted: boolean = !!submission?.isBoothCaptainAccepted;
    const isBadgeDisplayed: boolean = !isShiftAvailable || (!isPrePollingDayType && ((isBoothCaptainAccepted && !isBoothCaptainAvailable) || !isScrutineeringAvailable));

    useEffect(() => {
        const isSelected = locationInfo.locationID === (locationInfo.eventType === DAY_TYPE_ID.PRE_POLLING_DAYS ? selectedPrePollingLocationId : selectedElectionLocationId);
        setLocationSelected(isSelected);
    }, [selectedPrePollingLocationId, selectedElectionLocationId])

    const handleLocationTileClick = () => {
        if (isPrePollingDayType) {
            setSelectedPrePollingLocationId(locationInfo.locationID);
        } else {
            setSelectedElectionLocationId(locationInfo.locationID);
        }
    }

    const generateTileBadge = () => {
        return (
            <>
                <TileBadge isDisplayed={!isShiftAvailable}
                                  message="No Shift Available"
                                  isDisabled={true} />
                <TileBadge isDisplayed={isShiftAvailable && !isBoothCaptainAvailable && !isScrutineeringAvailable}
                                  message="Scrutineer & Booth Captain not Available" />
                <TileBadge isDisplayed={isShiftAvailable && !isBoothCaptainAvailable && isScrutineeringAvailable}
                                  message="Booth Captain not Available" />
                <TileBadge isDisplayed={isShiftAvailable && isBoothCaptainAvailable && !isScrutineeringAvailable}
                                  message="Scrutineering not Available" />
            </>
        );
    }

    return locationInfo ? (
        <Card onClick={handleLocationTileClick} className={`${className ?? ''} !block h-28 mb-4 relative items-center cursor-pointer !shadow-0.02 ${isLocationSelected ? 'border-[#0555B3]' : '#D5DADD'} ${!isShiftAvailable ? '!bg-[#F3F5F6] pointer-events-none' : ''}`}>
            {isBadgeDisplayed && generateTileBadge()}
            <div ref={containerRef} className={`flex justify-between items-center h-20 ${isBadgeDisplayed ? 'mt-5' : ''}`}>
                <div className="flex flex-col basis-10/12 text-sm md:text-base">
                    <h5 className={`font-bold tracking-tight ${isShiftAvailable ? 'text-[#00344B]' : 'text-[#67777F]'} dark:text-white whitespace-nowrap`}>
                        {LocationUtil.removePPVCFromLocationName(locationInfo?.locationName ?? '')}
                    </h5>
                    <p className={`font-normal ${isShiftAvailable ? 'text-[#00344B]' : 'text-[#67777F]'} dark:text-gray-400`}>
                        {LocationUtil.getStringLocationAddress(locationInfo)}
                    </p>
                </div>
                {isLocationSelected ? (<TileCheckIcon />) : (<TileUncheckIcon />)}
            </div>
        </Card>
    ) : <></>;
}

export default LocationTile;
