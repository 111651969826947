import DialogLayout from '../DialogLayout';

type NationBuilderErrorDialogProps = {
    message: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onYesButtonClick: () => void;
    onNoButtonClick: () => void;
}

const NationBuilderErrorDialog = (
    {
        message,
        isOpen,
        setOpen,
        onYesButtonClick,
        onNoButtonClick,
    }: NationBuilderErrorDialogProps) => {
    const title = `Something went wrong`;

    const handleYesButtonClick = () => {
        onYesButtonClick();
        setOpen(false);
    }

    const handleNoButtonClick = () => {
        onNoButtonClick();
        setOpen(false);
    }

    return (
        <DialogLayout title={title} isOpen={isOpen} setOpen={setOpen}>
            <div>
                <p className="px-4 text[#00344B] text-base">
                    {message}
                </p>
                <div>
                    <button className="w-full text-sm py-3 px-4 text-white bg-primary-orange font-extrabold mt-8 uppercase" onClick={handleYesButtonClick}>
                        REFRESH NOW
                    </button>
                    <button className="w-full text-sm py-3 px-4 font-extrabold border border-[#D5DADD] mt-2 uppercase text-[#002030]" onClick={handleNoButtonClick}>
                        I'LL TRY AGAIN LATER
                    </button>
                </div>
            </div>
        </DialogLayout>
    );
}

export default NationBuilderErrorDialog;
