import { ArrowSmallRightIcon } from '@heroicons/react/24/solid';
import { ReactNode } from 'react';

type NextButtonProps = {
    containerRef?: any;
    title: string | ReactNode;
    onClick: () => void;
    className?: string;
    additionalClassName?: string;
    isDisable?: boolean;
}

const NextButton = ({containerRef, title, onClick, className, additionalClassName = '', isDisable}: NextButtonProps) => {
    return (
        <button ref={containerRef}
                type="button"
                disabled={isDisable}
                className={`${additionalClassName} ${className ? className : 'basis-[49%] text-white md:basis-auto bg-primary-orange text-sm'} justify-between float-right flex items-center font-extrabold uppercase p-3 ${isDisable ? 'opacity-50' : ''}`}
                onClick={onClick}>
            <div className="min-w-[4.25rem]">{title}</div> <ArrowSmallRightIcon className="h-5 w-5 ml-2 font-thin" />
        </button>
    );
}

export default NextButton;
