import ContentLayout from '../../partials/ContentLayout';
import NextButton from '../../partials/NextButton';
import { useEffect, useState } from 'react';
import {
    ScheduleMap,
    ScheduleShiftGroup,
    SelectedShiftIdMap,
    ShiftContext,
    ShiftIdSelectionCheck,
    ShiftStore
} from './contexts/Shift.context';
import ShiftSelectionMobile from './components/ShiftSelectionMobile';
import { useSubmissionContext } from '../../contexts/Submission.context';
import { ScheduleService } from '../../services/Schedule.service';
import { LocationSchedule } from '../../models/LocationSchedule.model';
import { Schedule } from '../../models/Schedule.model';
import axios from 'axios';
import { Shift } from '../../models/Shift.model';
import ShiftSelectionMedium from './components/ShiftSelectionMedium';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShiftScheduleGroup, Submission, SubmissionShifts } from '../../models/Submission.model';
import { DAY_TYPE_ID } from '../../constants/DayTypeId.constant';
import AdditionalRoleDialog from '../../partials/AdditionalRoleDialog';
import { ADDITIONAL_ROLE_ID } from '../../constants/AdditionalRoleId.constant';
import { ROUTE_PATH } from '../../constants/RoutePath.constant';
import { SHIFT_ROLE } from '../../constants/Shift.constant';
import { ShiftRequestDTO } from '../../models/dto/ScheduleRequestDTO.model';
import _ from 'lodash';
import { LocalStorageUtil } from '../../utils/LocalStorage.util';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../constants/Breakpoint.constant';
import BackButton from '../../partials/BackButton';
import { LocationInfo } from '../../models/LocationInfo.model';
import MissingPrePollShiftNotificationDialog from './components/MissingPrePollShiftNotificationDialog';

const checkAtLeastOnePrePollShiftSelected = (submissionShifts: SubmissionShifts): boolean => {
    const shiftScheduleGroups: ShiftScheduleGroup[] = Object.values(submissionShifts);
    for (let i = 0; i < shiftScheduleGroups.length; i++) {
        const shiftScheduleGroup = shiftScheduleGroups?.[i];
        if (shiftScheduleGroup?.eventType === DAY_TYPE_ID.PRE_POLLING_DAYS
            && (shiftScheduleGroup?.standardShifts?.length > 0 || shiftScheduleGroup?.customShifts?.length > 0)) {
            return true;
        }
    }
    return false;
}

const ShiftSelectionPage = () => {
    const [searchParams] = useSearchParams();
    const isEditingSelectedShifts: boolean = searchParams.get('isEditing') === 'true';
    const [selectedScheduleId, setSelectedScheduleId] = useState<string>('');
    const [selectedShiftIdMap, setSelectedShiftIdMap] = useState<SelectedShiftIdMap>({} as SelectedShiftIdMap);
    const [locationSchedules, setLocationSchedules] = useState<LocationSchedule[]>([]);
    const [currentDisplayedShifts, setCurrentDisplayedShifts] = useState<Shift[]>([]);
    const [shiftStore, setShiftStore] = useState<ShiftStore>({});
    const { submission, setSubmission } = useSubmissionContext();
    const [isScheduleLoading, setScheduleLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isAdditionalBoothCaptainDialogOpen, setAdditionalBoothCaptainDialogOpen] = useState<boolean>(false);
    const [isAdditionalScrutineerDialogOpen, setAdditionalScrutineerDialogOpen] = useState<boolean>(false);
    const [isContinueButtonAvailable, setContinueButtonAvailable] = useState<boolean>(false);
    const [submissionShifts, setSubmissionShifts] = useState<SubmissionShifts>({});
    const [isAdditionalRoleAlreadySelected, setAdditionalRoleAlreadySelected] = useState<boolean>(false);
    const [scheduleMap, setScheduleMap] = useState<ScheduleMap>({});
    const dayTypeId: DAY_TYPE_ID = submission?.dayTypeId ?? DAY_TYPE_ID.NONE;
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const [isOpenMobileShiftList, setOpenMobileShiftList] = useState<boolean>(false);
    const [isMissingPrePollShiftNotificationDialogOpen, setMissingPrePollShiftNotificationDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        const storeSubmission: Submission = LocalStorageUtil.getStoredSubmission();
        if (storeSubmission?.nationBuilderId || storeSubmission?.nationbuilder_id) {
            if (!submission?.nationBuilderId && !submission?.nationbuilder_id) {
                setSubmission(storeSubmission);
            }
        }
    }, []);

    useEffect(() => {
        getLocationSchedules(!!submission?.isBoothCaptainAccepted);
    }, [submission]);

    useEffect(() => {
        if (selectedScheduleId && shiftStore?.[selectedScheduleId]) {
            setCurrentDisplayedShifts([...shiftStore?.[selectedScheduleId]?.standardShifts, ...shiftStore?.[selectedScheduleId]?.customShifts] ?? []);
        }
    }, [selectedScheduleId]);

    useEffect(() => {
        setContinueButtonAvailable(checkContinueButtonAvailable(selectedShiftIdMap));
    }, [selectedShiftIdMap]);

    useEffect(() => {
        if (!selectedScheduleId && submission?.isBoothCaptainAccepted) {
            handleAutoSelectBoothCaptainSchedule();
        }
    }, [shiftStore])

    const getLocationSchedules = (isBoothCaptainAccepted?: boolean, isScrutineerAccepted?: boolean) => {
        setScheduleLoading(true);
        axios.all([
            ScheduleService.getSchedulesByLocation(
                submission?.selectedElectionLocationInfo ?? {} as LocationInfo,
            ),
            ScheduleService.getSchedulesByLocation(
                submission?.selectedPrePollingLocationInfo ?? {} as LocationInfo,
            )
        ]).then(([electionSchedules, prePollingSchedules]: Array<Schedule[]>) => {
            const respondedLocationSchedules: LocationSchedule[] = [];
            if (submission?.selectedElectionLocationInfo?.locationID) {
                respondedLocationSchedules.push({
                    locationId: submission.selectedElectionLocationInfo?.locationID ?? '',
                    locationName: submission.selectedElectionLocationInfo?.locationName ?? '',
                    premisesName: submission.selectedElectionLocationInfo?.premisesName ?? '',
                    schedules: [...electionSchedules.map((schedule: Schedule) => {
                        return {
                            ...schedule,
                            dayTypeId: DAY_TYPE_ID.ELECTION_DAY,
                        }
                    })],
                });
            }
            if (submission?.selectedPrePollingLocationInfo?.locationID) {
                respondedLocationSchedules.push({
                    locationId: submission.selectedPrePollingLocationInfo?.locationID ?? '',
                    locationName: submission.selectedPrePollingLocationInfo?.locationName ?? '',
                    premisesName: submission.selectedElectionLocationInfo?.premisesName ?? '',
                    schedules: [...prePollingSchedules.map((schedule: Schedule) => {
                        return {
                            ...schedule,
                            dayTypeId: DAY_TYPE_ID.PRE_POLLING_DAYS,
                        }
                    })],
                });
            }
            const respondedScheduleMap: ScheduleMap = {};
            respondedLocationSchedules.forEach((locationSchedule: LocationSchedule) => {
                locationSchedule?.schedules?.forEach((schedule: Schedule) => {
                   respondedScheduleMap[schedule?.scheduleId] = schedule;
                });
            });
            setScheduleMap(respondedScheduleMap);
            setLocationSchedules([
                ...respondedLocationSchedules,
            ]);
            getAllShifts(
                _.flattenDeep(respondedLocationSchedules.map((locationSchedule: LocationSchedule) => locationSchedule.schedules)),
                isBoothCaptainAccepted,
                isScrutineerAccepted
            );
            setScheduleLoading(false);
        }).catch((error: any) => {
            setScheduleLoading(false);
            console.error('ShiftSelectionPage > initLocationSchedules has an error', error);
        });
    }

    const getAllShifts = (schedules: Schedule[], isBoothCaptainAccepted?: boolean, isScrutineerAccepted?: boolean) => {
        axios.all(schedules.map((schedule: Schedule) => getShifts(schedule, isBoothCaptainAccepted)))
            .then((allShifts: Shift[][]) => {
                const scheduleShiftGroups: ScheduleShiftGroup[] = allShifts.map((shifts: Shift[], index: number) => {
                    return {
                        schedule: schedules[index],
                        shifts: shifts.map((shift: Shift) => {
                            return {
                                ...shift,
                                scheduleId: schedules[index].scheduleId,
                            };
                        }),
                    };
                });
                const newShiftStore: ShiftStore = {};
                scheduleShiftGroups.forEach((scheduleShiftGroup: ScheduleShiftGroup) => {
                    const scheduleId: string = scheduleShiftGroup?.schedule?.scheduleId ?? '';
                    const respondedShifts: Shift[] = [...(scheduleShiftGroup?.shifts ?? [])];
                    const localStorageCustomShifts = LocalStorageUtil.getStoredSelectedCustomShifts(scheduleId);
                    const existingCustomShifts = localStorageCustomShifts.length > 0
                        ? localStorageCustomShifts
                        : isBoothCaptainAccepted ? localStorageCustomShifts : [...(shiftStore?.[scheduleId]?.customShifts ?? [])];
                    newShiftStore[scheduleId] = {
                        standardShifts: respondedShifts.sort((shiftA: Shift, shiftB: Shift) => shiftA.endTime - shiftB.endTime),
                        customShifts: existingCustomShifts,
                    };
                });
                setShiftStore({
                    ...shiftStore,
                    ...newShiftStore,
                });
                if (!isEditingSelectedShifts || isBoothCaptainAccepted) {
                    handleAutoSelectedShift(scheduleShiftGroups, isBoothCaptainAccepted, isScrutineerAccepted);
                }
                initEditingData(isEditingSelectedShifts);
            })
            .catch((error: any) => {
                console.error('ShiftLocationPage > getShifts has an error', error);
            });
    }

    const getShifts = (schedule: Schedule, isBoothCaptainAccepted?: boolean) => {
        return ScheduleService.getShiftsByScheduleId(
            schedule?.scheduleId,
            getShiftRequestDTOByDayTypeId(schedule?.dayTypeId ?? DAY_TYPE_ID.NONE, isBoothCaptainAccepted)
        );
    }

    const handleContinueButtonClick = (skipPreCondition?: boolean) => {
        const submissionShifts: SubmissionShifts = getSubmissionShifts();
        setSubmissionShifts(submissionShifts);
        let isPreConditionPassed = true;
        if (!!submission?.selectedPrePollingLocationInfo?.locationID
            && (submission?.dayTypeId === DAY_TYPE_ID.BOTH || submission?.selectedDayTypeId === DAY_TYPE_ID.BOTH)) {
            isPreConditionPassed = checkAtLeastOnePrePollShiftSelected(submissionShifts);
        }
        const selectedElectionLocationAvailable = submission?.selectedElectionLocationInfo?.locationAvail;
        if (skipPreCondition || isPreConditionPassed) {
            if (!isEditingSelectedShifts
                && isBoothCaptainShiftSelected(submissionShifts)
                && !isScrutineerShiftSelected(submissionShifts)
                && !isAdditionalRoleAlreadySelected
                && !!selectedElectionLocationAvailable?.isScrutineeringAvailable
            ) {
                setAdditionalScrutineerDialogOpen(true);
            } else {
                if (!isEditingSelectedShifts
                    && isSelectedMoreThanOneElectionStandardVolunteerShift(submissionShifts)
                    && !isAdditionalRoleAlreadySelected
                    && !!selectedElectionLocationAvailable?.isBoothCaptainAvailable
                ) {
                    setAdditionalBoothCaptainDialogOpen(true);
                } else {
                    navigateToSummaryPage(submissionShifts);
                }
            }
        } else {
            setMissingPrePollShiftNotificationDialogOpen(true);
        }
    }

    const getSubmissionShifts = () => {
        const submissionShifts: SubmissionShifts = {};
        for (const scheduleId in selectedShiftIdMap) {
            const selectedShiftIds: string[] = Object.keys(selectedShiftIdMap?.[scheduleId] ?? {})?.filter(
                (shiftId: string) => selectedShiftIdMap?.[scheduleId]?.[shiftId]
            );
            const {locationSchedule, schedule} = getLocationScheduleAndScheduleByScheduleId(scheduleId);
            if (schedule?.scheduleId) {
                const eventType: DAY_TYPE_ID = getEventTypeByLocationId(locationSchedule.locationId);
                const standardShifts: Shift[] = [];
                const customShifts: Shift[] = [];
                selectedShiftIds.forEach((shiftId: string) => {
                    standardShifts.push(shiftStore[scheduleId]?.standardShifts?.find((shift: Shift) => shift.id === shiftId) ?? {} as Shift);
                    customShifts.push(shiftStore[scheduleId]?.customShifts?.find((shift: Shift) => shift.id === shiftId) ?? {} as Shift);
                });
                submissionShifts[scheduleId] = {
                    eventType,
                    schedule: schedule,
                    standardShifts: standardShifts.filter((shift: Shift) => !!shift?.id),
                    customShifts: customShifts.filter((shift: Shift) => !!shift?.id),
                }
            }
        }
        return submissionShifts;
    }

    const getLocationScheduleAndScheduleByScheduleId = (scheduleId: string) => {
        for (let i = 0; i < locationSchedules?.length; i++) {
            const foundedSchedule: Schedule = locationSchedules[i].schedules.find(
                (findingSchedule: Schedule) => findingSchedule?.scheduleId === scheduleId
            ) ?? {} as Schedule;
            if (foundedSchedule?.scheduleId) {
                return {
                    locationSchedule: locationSchedules[i],
                    schedule: foundedSchedule,
                };
            }
        }
        return {};
    }

    const getEventTypeByLocationId = (locationId: string): DAY_TYPE_ID => {
        const dayTypeId: DAY_TYPE_ID = submission?.dayTypeId ?? submission?.selectedDayTypeId ?? DAY_TYPE_ID.NONE;
        switch (dayTypeId) {
            case DAY_TYPE_ID.PRE_POLLING_DAYS:
                return DAY_TYPE_ID.PRE_POLLING_DAYS;
            case DAY_TYPE_ID.ELECTION_DAY:
                return DAY_TYPE_ID.ELECTION_DAY;
            case DAY_TYPE_ID.BOTH:
                if (submission?.selectedElectionLocationInfo?.locationID === locationId) {
                    return DAY_TYPE_ID.ELECTION_DAY;
                } else if (submission?.selectedPrePollingLocationInfo?.locationID === locationId) {
                    return DAY_TYPE_ID.PRE_POLLING_DAYS;
                }
                return DAY_TYPE_ID.NONE;
            default:
                return DAY_TYPE_ID.NONE;
        }
    }

    const checkContinueButtonAvailable = (shiftIdGroup: SelectedShiftIdMap): boolean => {
        let isContinueButtonAvailable = false;
        for (const scheduleId in shiftIdGroup) {
            const selectedShiftIds: string[] = Object.keys(shiftIdGroup?.[scheduleId] ?? {})?.filter(
                (shiftId: string) => selectedShiftIdMap?.[scheduleId]?.[shiftId]
            );
            if (selectedShiftIds.length > 0) {
                isContinueButtonAvailable = true;
            }
        }
        return isContinueButtonAvailable;
    }

    const handleAdditionalBoothCaptainYesClick = () => {
        const updatedSubmission: Submission = {
            ...submission,
            isBoothCaptainAccepted: true,
        };
        LocalStorageUtil.setStoredSubmission(updatedSubmission);
        setSubmission(updatedSubmission);
        emptyCustomShiftsForBoothCaptain();
        setAdditionalRoleAlreadySelected(true);
        setCurrentDisplayedShifts([]);
        setSelectedScheduleId('');
        getLocationSchedules(true);
    }

    const handleAdditionalScrutineerYesClick = () => {
        setAdditionalRoleAlreadySelected(true);
        setCurrentDisplayedShifts([]);
        setSelectedScheduleId('');
        getLocationSchedules(submission?.isBoothCaptainAccepted, true);
    }

    const isBoothCaptainShiftSelected = (submissionShifts: SubmissionShifts): boolean => {
        let isBoothCaptainShiftSelected = false;
        for(let scheduleId in submissionShifts) {
            const selectedStandardShifts = submissionShifts?.[scheduleId]?.standardShifts ?? [];
            for (let i = 0; i < selectedStandardShifts.length; i++) {
                if (selectedStandardShifts[i].role === SHIFT_ROLE.BOOTH_CAPTAIN) {
                    isBoothCaptainShiftSelected = true;
                    break;
                }
            }
        }
        return isBoothCaptainShiftSelected;
    }

    const isScrutineerShiftSelected = (submissionShifts: SubmissionShifts): boolean => {
        let isScrutineerShiftSelected = false;
        for(let scheduleId in submissionShifts) {
            const selectedStandardShifts = submissionShifts?.[scheduleId]?.standardShifts ?? [];
            for (let i = 0; i < selectedStandardShifts.length; i++) {
                if (selectedStandardShifts[i].role === SHIFT_ROLE.SCRUTINEER) {
                    isScrutineerShiftSelected = true;
                    break;
                }
            }
        }
        return isScrutineerShiftSelected;
    }

    const isSelectedMoreThanOneElectionStandardVolunteerShift = (submissionShifts: SubmissionShifts): boolean => {
        return countSubmissionElectionStandardVolunteerShifts(submissionShifts) > 1;
    }

    const countSubmissionElectionStandardVolunteerShifts = (submissionShifts: SubmissionShifts): number => {
        let numberOfSubmissionStandardShifts = 0;
        for(let scheduleId in submissionShifts) {
            const isElectionDay = submissionShifts?.[scheduleId]?.eventType === DAY_TYPE_ID.ELECTION_DAY ?? DAY_TYPE_ID.NONE;
            if (isElectionDay) {
                numberOfSubmissionStandardShifts += (submissionShifts?.[scheduleId]?.standardShifts.filter(
                    (shift: Shift) => shift.role === SHIFT_ROLE.VOLUNTEER
                ))?.length ?? 0;
            }
        }
        return numberOfSubmissionStandardShifts;
    }

    const navigateToSummaryPage = (submissionShifts: SubmissionShifts) => {
        const updatedSubmission: Submission = {
            ...submission,
            selectedSubmissionShifts: submissionShifts,
        };
        LocalStorageUtil.setStoredSubmission(updatedSubmission);
        setSubmission(updatedSubmission);
        navigate(`/${ROUTE_PATH.SUMMARY}`);
    }

    const initEditingData = (isEditing: boolean) => {
        if (isEditing) {
            const editingSelectedShiftIdGroup: SelectedShiftIdMap = {};
            const selectedSubmissionShifts = submission?.selectedSubmissionShifts ?? {};
            for (const scheduleId in selectedSubmissionShifts) {
                const shifts: Shift[] = [...selectedSubmissionShifts[scheduleId].standardShifts, ...selectedSubmissionShifts[scheduleId].customShifts];
                shifts.forEach((shift: Shift) => {
                    if (shift?.id) {
                        editingSelectedShiftIdGroup[scheduleId] = {
                            ...editingSelectedShiftIdGroup[scheduleId],
                            [shift.id]: true,
                        }
                    }
                });
            }
            setSelectedShiftIdMap({
                ...selectedShiftIdMap,
                ...editingSelectedShiftIdGroup,
            });
        }
    }

    const getShiftRequestDTOByDayTypeId = (dayTypeId: DAY_TYPE_ID, isBoothCaptainAccepted?: boolean): ShiftRequestDTO => {
        const isBoothCaptain: boolean = isBoothCaptainAccepted ?? !!submission?.isBoothCaptainAccepted;
        switch (dayTypeId) {
            case DAY_TYPE_ID.ELECTION_DAY:
                return {
                    isBoothCaptain,
                    isScrutineer: true,
                    isVolunteer: !isBoothCaptain,
                    volunteersRequired: submission?.selectedElectionLocationInfo?.volunteersRequired ?? 0,
                };
            case DAY_TYPE_ID.PRE_POLLING_DAYS:
                return {
                    isBoothCaptain: false,
                    isScrutineer: false,
                    isVolunteer: true,
                    volunteersRequired: submission?.selectedPrePollingLocationInfo?.volunteersRequired ?? 0,
                };
            default:
                return {
                    isBoothCaptain: false,
                    isScrutineer: false,
                    isVolunteer: false,
                    volunteersRequired: 0,
                }
        }
    }

    const handleAutoSelectedShift = (scheduleShiftGroups: ScheduleShiftGroup[], isBoothCaptainAccepted?: boolean, isScrutineerAccepted?: boolean) => {
        const newSelectedShiftIdMap: SelectedShiftIdMap = isBoothCaptainAccepted ? {} : {...selectedShiftIdMap};
        const isLocationBoothCaptainAvailable: boolean = !!submission?.selectedElectionLocationInfo?.locationAvail?.isBoothCaptainAvailable;
        const isLocationScrutineerAvailable: boolean = !!submission?.selectedElectionLocationInfo?.locationAvail?.isScrutineeringAvailable;
        // keep shifts that are not booth captain shifts
        for (const scheduleId in selectedShiftIdMap) {
            const schedule = scheduleMap[scheduleId];
            scheduleShiftGroups.forEach((scheduleShiftGroup: ScheduleShiftGroup) => {
                const groupedScheduleId = scheduleShiftGroup?.schedule?.scheduleId;
                if (groupedScheduleId === scheduleId) {
                    const shifts: Shift[] = scheduleShiftGroup?.shifts ?? [];
                    shifts.forEach((shift: Shift) => {
                        if ((isBoothCaptainAccepted && shift.role === SHIFT_ROLE.SCRUTINEER)) {
                            newSelectedShiftIdMap[scheduleId] = {
                                ...newSelectedShiftIdMap[scheduleId],
                                [shift.id]: !!selectedShiftIdMap?.[scheduleId]?.[shift.id]
                            }
                        }
                    });
                    if (schedule.dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
                        newSelectedShiftIdMap[scheduleId] = {
                            ...newSelectedShiftIdMap[scheduleId],
                            ...selectedShiftIdMap[scheduleId],
                        }
                    }
                }
            });
        }
        scheduleShiftGroups.forEach((scheduleShiftGroup: ScheduleShiftGroup) => {
            const schedule: Schedule = scheduleShiftGroup.schedule ?? {};
            const shifts: Shift[] = scheduleShiftGroup.shifts ?? [];
            if (schedule?.scheduleId && schedule?.dayTypeId === DAY_TYPE_ID.ELECTION_DAY && (isLocationBoothCaptainAvailable || isLocationScrutineerAvailable)) {
                const boothCaptainShiftIds: string[] = [];
                const scrutineerShiftIds: string[] = [];
                shifts.forEach((shift: Shift) => {
                    if (shift.isAvailable) {
                        switch (shift.role) {
                            case SHIFT_ROLE.BOOTH_CAPTAIN:
                                if (isLocationBoothCaptainAvailable) {
                                    boothCaptainShiftIds.push(shift.id);
                                }
                                break;
                            case SHIFT_ROLE.SCRUTINEER:
                                if (isLocationScrutineerAvailable) {
                                    scrutineerShiftIds.push(shift.id);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                });
                const boothCaptainShiftIdMapValues: ShiftIdSelectionCheck = {};
                if (isBoothCaptainAccepted ?? submission?.isBoothCaptainAccepted) {
                    boothCaptainShiftIds.forEach((id: string) => {
                        boothCaptainShiftIdMapValues[id] = true;
                    });
                }
                const scrutineerShiftIdMapValues: ShiftIdSelectionCheck = {};
                if (isScrutineerAccepted) {
                    scrutineerShiftIds.forEach((id: string) => {
                        scrutineerShiftIdMapValues[id] = true;
                    });
                }
                newSelectedShiftIdMap[schedule.scheduleId] = {
                    ...newSelectedShiftIdMap[schedule.scheduleId],
                    ...boothCaptainShiftIdMapValues,
                    ...scrutineerShiftIdMapValues,
                }
            }
        });
        setSelectedShiftIdMap(newSelectedShiftIdMap);
    }

    const handleAutoSelectBoothCaptainSchedule = () => {
        for (const scheduleId in scheduleMap) {
            if (scheduleMap[scheduleId].dayTypeId === DAY_TYPE_ID.ELECTION_DAY) {
                const schedule = scheduleMap[scheduleId];
                setSelectedScheduleId(schedule.scheduleId);
                if (isXsScreen) {
                    setOpenMobileShiftList(true);
                }
            }
        }
    }

    const emptyCustomShiftsForBoothCaptain = () => {
        for (const scheduleId in selectedShiftIdMap) {
            const schedule: Schedule = scheduleMap[scheduleId];
            if (schedule.dayTypeId === DAY_TYPE_ID.ELECTION_DAY) {
                LocalStorageUtil.setStoredSelectedCustomShifts(scheduleId, []);
            }
        }
    }

    const handleBackButtonClick = () => {
        navigate(`/${ROUTE_PATH.LOCATION_SELECTION}?isEditing=true`);
    }

    const handleSkipMissingPrePollButtonClick = () => {
        setMissingPrePollShiftNotificationDialogOpen(false);
        handleContinueButtonClick(true);
    }

    return (
        <ContentLayout title="Select your shifts"
                       stepNumber={dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? 2 : 3}
                       footerContent={(
                           <div className="flex items-center justify-between w-full">
                               <BackButton onClick={handleBackButtonClick} />
                               <NextButton title="Continue" isDisable={!isContinueButtonAvailable} onClick={() => handleContinueButtonClick()} />
                           </div>
                       )}>
            <ShiftContext.Provider value={{
                selectedScheduleId,
                setSelectedScheduleId,
                selectedShiftIdMap,
                setSelectedShiftIdMap,
                locationSchedules,
                setLocationSchedules,
                currentDisplayedShifts,
                setCurrentDisplayedShifts,
                isScheduleLoading,
                setScheduleLoading,
                shiftStore,
                setShiftStore,
                scheduleMap,
                setScheduleMap,
            }}>
                <div className="sm:hidden">
                    <ShiftSelectionMobile isOpenShiftList={isOpenMobileShiftList}
                                          setOpenShiftList={setOpenMobileShiftList} />
                </div>
                <div className="hidden sm:block">
                    <ShiftSelectionMedium />
                </div>
                <AdditionalRoleDialog additionalRoleId={ADDITIONAL_ROLE_ID.BOOTH_CAPTAIN}
                                      message="A booth captain is required for the whole polling day and is in charge of managing the logistics of the booth, including setting up, packing down and coordinating volunteers throughout the day. Captains will be provided with materials and training."
                                      isOpen={isAdditionalBoothCaptainDialogOpen}
                                      setOpen={setAdditionalBoothCaptainDialogOpen}
                                      onYesButtonClick={handleAdditionalBoothCaptainYesClick}
                                      onNoButtonClick={() => navigateToSummaryPage(submissionShifts)} />
                <AdditionalRoleDialog additionalRoleId={ADDITIONAL_ROLE_ID.SCRUTINEER}
                                      message="A scrutineer is a vital role once the booth closes. You will be part of an engaged team that watches AEC staff count the total votes for your booth and record results for the campaign. All scrutineers will receive training."
                                      isOpen={isAdditionalScrutineerDialogOpen}
                                      setOpen={setAdditionalScrutineerDialogOpen}
                                      onYesButtonClick={handleAdditionalScrutineerYesClick}
                                      onNoButtonClick={() => navigateToSummaryPage(submissionShifts)} />
                <MissingPrePollShiftNotificationDialog isOpen={isMissingPrePollShiftNotificationDialogOpen}
                                                       setOpen={setMissingPrePollShiftNotificationDialogOpen}
                                                       onSkip={handleSkipMissingPrePollButtonClick} />
            </ShiftContext.Provider>
        </ContentLayout>
    );
}

export default ShiftSelectionPage;
