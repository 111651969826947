import StepInfo from '../StepInfo';
import { ReactNode } from 'react';

type HeaderProps = {
    containerRef?: any;
    stepNumber?: number;
    stepTitle: string;
}

const Header = ({containerRef, stepNumber, stepTitle}: HeaderProps) => {

    return (
        <div ref={containerRef} className="py-6 px-4 border-b-[0.05rem] border-[#D5DADD] md:px-8">
            {stepNumber && (<StepInfo stepNumber={stepNumber} />)}
            <h3 className="font-extrabold text-[1.75rem] leading-[140%] uppercase text-[#0E5173]">
                {stepTitle}
            </h3>
        </div>
    );
}

export default Header;
