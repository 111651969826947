import { Shift } from '../../../../../../../models/Shift.model';
import moment from 'moment/moment';
import { SHIFT_ROLE_NAME } from '../../../../../../../constants/Shift.constant';
import { ShiftUtil } from '../../../../../../../utils/Shift.util';
import { TIME_FORMAT } from '../../../../../../../constants/Time.constant';

type ShiftSummaryRowProps = {
    shift: Shift;
}

const ShiftSummaryRow = ({shift}: ShiftSummaryRowProps) => {
    const startTime: string = shift?.startTime ? moment.utc(shift.startTime).format(TIME_FORMAT.HOUR_12) : '';
    const endTime: string = shift?.endTime ? moment.utc(shift.endTime).format(TIME_FORMAT.HOUR_12) : '';
    const role: SHIFT_ROLE_NAME = ShiftUtil.getRole(shift?.role);

    return (
        <div className="border-b border-[#D5DADD] pt-4 pb-2 text-[#67777F]">
            <div className="font-bold">{startTime} - {endTime}</div>
            <div>{role}</div>
        </div>
    );
}

export default ShiftSummaryRow;
