import { createContext, useContext } from 'react';

export type LocationContextProp = {
    selectedPrePollingLocationId: string,
    setSelectedPrePollingLocationId: (selectedPrePollingLocationId: any) => void,
    selectedElectionLocationId: string,
    setSelectedElectionLocationId: (selectedElectionLocationId: any) => void,
    isPrePollingLocationLoading: boolean,
    setPrePollingLocationLoading: (isLocationLoading: boolean) => void,
    isElectionLocationLoading: boolean,
    setElectionLocationLoading: (isLocationLoading: boolean) => void,
}

export const LocationContext = createContext<LocationContextProp>({
    selectedPrePollingLocationId: '',
    setSelectedPrePollingLocationId: () => {},
    selectedElectionLocationId: '',
    setSelectedElectionLocationId: () => {},
    isPrePollingLocationLoading: false,
    setPrePollingLocationLoading: () => {},
    isElectionLocationLoading: false,
    setElectionLocationLoading: () => {},
})

export const useLocationContext = () => useContext(LocationContext);
