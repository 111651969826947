import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';
import { LocationInfo } from '../../../../models/LocationInfo.model';
import { ReactNode } from 'react';
import { DEFAULT_TIME_RANGE } from '../../../../constants/Shift.constant';
import { DAY_TYPE_ID } from '../../../../constants/DayTypeId.constant';
import { useElementSize } from 'usehooks-ts';
import { LocationSectionContext } from './contexts/LocationSection.context';

type LocationSectionProps = {
    listTitle: string;
    locationInfos: LocationInfo[];
    className?: string;
    children: ReactNode;
}

const DEFAULT_PREPOLL_DAY_RANGE = 'Mon, Oct 2nd - Fri, Oct 13th';
const DEFAULT_ELECTION_DAY_RANGE = 'Sat, Oct 14th';

const LocationSection = ({
    listTitle,
    locationInfos,
    className,
    children,
}: LocationSectionProps) => {
    const sortedLocationInfos: LocationInfo[] = locationInfos.sort(
        (locationInfoA: LocationInfo, locationInfoB: LocationInfo) => locationInfoA.endTime - locationInfoB.endTime
    );
    const eventTypeId: DAY_TYPE_ID = sortedLocationInfos?.[0]?.eventType ?? DAY_TYPE_ID.NONE;
    const [descriptionRef, { height: eventDescriptionHeight }] = useElementSize();

    return (
        <LocationSectionContext.Provider value={{eventDescriptionHeight}}>
            <div className={`p-4 md:px-10 md:pt-6 md:pb-8 ${className ?? ''}`}>
                <div ref={descriptionRef} className="mb-6">
                    <div className="font-bold mb-6 text-lg whitespace-nowrap">{listTitle}</div>
                    <div className="text-[#67777F]">
                        <div className="flex items-center whitespace-nowrap">
                            <CalendarIcon className="w-5 h-5" />
                            <div className="pl-3">{eventTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? DEFAULT_PREPOLL_DAY_RANGE : DEFAULT_ELECTION_DAY_RANGE}</div>
                        </div>
                        <div className="flex items-center whitespace-nowrap">
                            <ClockIcon className="w-5 h-5" />
                            <div className="pl-3">Shifts available from {DEFAULT_TIME_RANGE}</div>
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </LocationSectionContext.Provider>
    );
}

export default LocationSection;
