import { createContext, useContext } from 'react';
import { LocationSchedule } from '../../../models/LocationSchedule.model';
import { Shift } from '../../../models/Shift.model';
import { Schedule } from '../../../models/Schedule.model';

export type ScheduleMap = {
    [scheduleId: string]: Schedule;
}

export type SelectedShiftIdMap = {
    [scheduleId: string]: ShiftIdSelectionCheck;
}

export type ShiftIdSelectionCheck = {
    [shiftId: string]: boolean;
}

export type ShiftStore = {
    [scheduleId: string]: ShiftStoreList;
}

export type ScheduleShiftGroup = {
    schedule: Schedule;
    shifts: Shift[];
}

export type ShiftStoreList = {
    standardShifts: Shift[];
    customShifts: Shift[];
}

export type ShiftContextProp = {
    selectedScheduleId: string,
    setSelectedScheduleId: (selectedScheduleId: string) => void,
    selectedShiftIdMap: SelectedShiftIdMap,
    setSelectedShiftIdMap: (selectedShiftIdMap: SelectedShiftIdMap) => void,
    locationSchedules: LocationSchedule[],
    setLocationSchedules: (locationSchedules: LocationSchedule[]) => void,
    currentDisplayedShifts: Shift[],
    setCurrentDisplayedShifts: (shifts: Shift[]) => void,
    isScheduleLoading: boolean,
    setScheduleLoading: (isShiftLoading: boolean) => void,
    shiftStore: ShiftStore,
    setShiftStore: (shiftStore: ShiftStore) => void,
    scheduleMap: ScheduleMap,
    setScheduleMap: (scheduleMap: ScheduleMap) => void,
}

export const ShiftContext = createContext<ShiftContextProp>({
    selectedScheduleId: '',
    setSelectedScheduleId: () => {},
    selectedShiftIdMap: {},
    setSelectedShiftIdMap: () => {},
    locationSchedules: [],
    setLocationSchedules: () => {},
    currentDisplayedShifts: [],
    setCurrentDisplayedShifts: () => {},
    isScheduleLoading: false,
    setScheduleLoading: () => {},
    shiftStore: {},
    setShiftStore: () => {},
    scheduleMap: {},
    setScheduleMap: () => {},
})

export const useShiftContext = () => useContext(ShiftContext);
