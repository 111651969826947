import { Address } from '../models/Address.model';

const getStringLocationAddress = (location: any) => {
    const locationAddress: Address = location?.locationAddress ?? location?.detail?.address ?? location?.address;
    const streetAddress = [locationAddress?.line1, locationAddress?.line2, locationAddress?.line3]
        .filter((line: string) => !!line)
        .join(' ');
    return locationAddress
        ? [
            location?.premisesName,
            streetAddress,
            locationAddress.city,
        ].filter((value: string) => !!value).join(', ')
        : '';
}

const removePPVCFromLocationName = (locationName: string) => {
    return locationName?.replace(' PPVC', '')?.trim() ?? locationName;
}

export const LocationUtil = {
    getStringLocationAddress,
    removePPVCFromLocationName,
}
