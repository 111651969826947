import { createContext, useContext } from 'react';

export type ShiftMobileContextProp = {
    isOpenShiftList: boolean,
    setOpenShiftList: (isOpenShiftList: boolean) => void,
    saveShiftButtonHeight: number,
}

export const ShiftMobileContext = createContext<ShiftMobileContextProp>({
    isOpenShiftList: false,
    setOpenShiftList: () => {},
    saveShiftButtonHeight: 0,
});

export const useShiftMobileContext = () => useContext(ShiftMobileContext);
