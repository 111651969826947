import { Dialog } from '@material-tailwind/react';
import { ReactNode } from 'react';

type DialogLayoutProps = {
    title: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    children: ReactNode;
}
const DialogLayout = ({title, isOpen, setOpen, children}: DialogLayoutProps) => {
    return (
        <Dialog open={isOpen} handler={() => setOpen(!isOpen)} className="text-[#0E5173] w-[90%] max-w-full font-['Rubik'] font-semibold text-center md:w-3/5 lg:min-w-[25%] lg:w-2/6 rounded-none">
            <div className="text-[1.625rem] px-10 pt-6 pb-4 border-b-[0.8px] uppercase font-extrabold">
                {title}
            </div>
            <div className="py-8 px-4 md:py-10 md:px-14">
                <div>{children}</div>
            </div>
        </Dialog>
    )
}

export default DialogLayout;
