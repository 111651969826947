import { Shift } from '../models/Shift.model';
import { LOCAL_STORAGE } from '../constants/LocalStorage.constant';
import { ShiftStore } from '../pages/ShiftSelectionPage/contexts/Shift.context';
import { Submission } from '../models/Submission.model';
import { SharedSchedule } from '../models/BoothCaptainSharedSchedule/SharedSchedule.model';

const setLocalStorage = (key: string, value: string) => {
    try {
        localStorage.setItem(key, value);
    } catch (error: any) {
        console.error('setLocalStorage has an error', error);
    }
}

const getLocalStorage = (key: string) => {
    try {
        return localStorage.getItem(key);
    } catch (error: any) {
        console.error('setLocalStorage has an error', error);
        return null;
    }
}

const getStoredSelectedCustomShifts = (scheduleId: string): Shift[] => {
    const localStorageEditingShiftStore = JSON.parse(getLocalStorage(LOCAL_STORAGE.EDITING_SHIFT_STORE) ?? '{}');
    return localStorageEditingShiftStore?.[scheduleId]?.customShifts ?? [];
}

const setStoredSelectedCustomShifts = (scheduleId: string, customShifts: Shift[]) => {
    const localStorageEditingShiftStore: ShiftStore = JSON.parse(getLocalStorage(LOCAL_STORAGE.EDITING_SHIFT_STORE) ?? '{}');
    if (localStorageEditingShiftStore?.[scheduleId]?.customShifts) {
        localStorageEditingShiftStore[scheduleId].customShifts = customShifts;
        setStoredShiftStore(localStorageEditingShiftStore);
    }
}

const setStoredShiftStore = (shiftStore: ShiftStore) => {
    setLocalStorage(LOCAL_STORAGE.EDITING_SHIFT_STORE, JSON.stringify(shiftStore?? []));
}

const getStoredSubmission = (): Submission => {
    return JSON.parse(getLocalStorage(LOCAL_STORAGE.SUBMISSION) ?? '{}');
}

const setStoredSubmission = (submission: Submission | string) => {
    setLocalStorage(LOCAL_STORAGE.SUBMISSION, JSON.stringify(submission));
}

const clearData = () => {
    setStoredSubmission('');
    setStoredShiftStore({});
}

const getSharedSchedule = (): SharedSchedule => {
    return JSON.parse(getLocalStorage(LOCAL_STORAGE.SHARED_SCHEDULE) ?? '{}');
}

const setSharedSchedule = (sharedSchedule: SharedSchedule) => {
    setLocalStorage(LOCAL_STORAGE.SHARED_SCHEDULE, JSON.stringify(sharedSchedule));
}

const getSharedScheduleCode = (): string => {
    return getLocalStorage(LOCAL_STORAGE.SHARED_SCHEDULE_CODE) ?? '';
}

const setSharedScheduleCode = (sharedSchedule: string) => {
    setLocalStorage(LOCAL_STORAGE.SHARED_SCHEDULE_CODE, sharedSchedule);
}

const clearSharedScheduleData = () => {
    setSharedSchedule({} as SharedSchedule);
    setSharedScheduleCode('');
}

export const LocalStorageUtil = {
    getStoredSelectedCustomShifts,
    setStoredSelectedCustomShifts,
    setStoredShiftStore,
    getStoredSubmission,
    setStoredSubmission,
    clearData,
    getSharedSchedule,
    setSharedSchedule,
    getSharedScheduleCode,
    setSharedScheduleCode,
    clearSharedScheduleData,
}
