import { createContext, useContext } from 'react';

export type ShiftSectionContextProp = {
    shiftSectionHeight: number,
    shiftSectionHeaderHeight: number,
}

export const ShiftSectionContext = createContext<ShiftSectionContextProp>({
    shiftSectionHeight: 0,
    shiftSectionHeaderHeight: 0,
})

export const useShiftSectionContext = () => useContext(ShiftSectionContext);
