const ClockIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z" stroke="#002030" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M10.5 6.125V10.5H14.875" stroke="#002030" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    );
}

export default ClockIcon;
