import { ShiftIdSelectionCheck, ShiftStoreList, useShiftContext } from '../../contexts/Shift.context';
import { Shift } from '../../../../models/Shift.model';
import ShiftTile from './components/ShiftTile';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../../../constants/Breakpoint.constant';
import CustomShiftDialog from './components/CustomShiftDialog';
import { ObjectUtil } from '../../../../utils/Object.util';
import moment from 'moment';
import { DEFAULT_TIME_ITEM_FORMAT } from './components/CustomShiftDialog/components/TimeDropdown';
import { Schedule } from '../../../../models/Schedule.model';
import { SHIFT_ROLE, SHIFT_TYPE } from '../../../../constants/Shift.constant';
import { useSubmissionContext } from '../../../../contexts/Submission.context';
import { DAY_TYPE_ID } from '../../../../constants/DayTypeId.constant';
import { LocalStorageUtil } from '../../../../utils/LocalStorage.util';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { useRightDrawerContext } from '../../../../partials/RightDrawer/contexts/RightDrawer.context';
import { useShiftMobileContext } from '../../contexts/ShiftMobile.context';
import { RIGHT_DRAWER_HEADER_MARGIN_BOTTOM, RIGHT_DRAWER_PADDING_TOP } from '../../../../partials/RightDrawer';
import { NumberUtil } from '../../../../utils/Number.util';
import { useShiftSectionContext } from '../ShiftSection/contexts/ShiftSection.context';

const CUSTOM_SHIFT_MESSAGE_MARGIN_TOP = 32;
const SAVE_SHIFT_BUTTON_MARGIN_BOTTOM: number = 12;
const SHIFT_TILE_PADDING_Y = 48;
const SHIFT_TILE_MARGIN_BOTTOM = 12;
const MD_SHIFT_SECTION_MARGIN_TOP = 24;
const MD_SHIFT_SECTION_MARGIN_BOTTOM = 16;
const MD_SHIFT_SECTION_HEADER_MARGIN_BOTTOM = 24;
const SM_SHIFT_SECTION_MARGIN_Y = 24 * 2;

export enum CUSTOM_SHIFT_TIME_ERROR {
    NONE,
    INVALID_RANGE,
    MORE_THAN_150_MINUTES,
    OVERLAP,
}

type ShiftTimeMap = {
    [shiftId: string]: ShiftTime;
}

type ShiftTime = {
    startTime: number;
    endTime: number;
}

const getCustomShiftTimeError = (startTime: string, endTime: string, selectedShiftTimes: ShiftTime[], scheduleDate: string): CUSTOM_SHIFT_TIME_ERROR => {
    const formattedStartTime = moment(startTime, DEFAULT_TIME_ITEM_FORMAT);
    const formattedEndTime = moment(endTime, DEFAULT_TIME_ITEM_FORMAT);
    const differentMinutes = moment.duration(formattedEndTime.diff(formattedStartTime)).asMinutes();
    if (differentMinutes <= 0) {
        return CUSTOM_SHIFT_TIME_ERROR.INVALID_RANGE;
    } else if (differentMinutes > 150) {
        return CUSTOM_SHIFT_TIME_ERROR.MORE_THAN_150_MINUTES;
    } else if (checkOverlapShiftTimes(startTime, endTime, selectedShiftTimes, scheduleDate)) {
        return CUSTOM_SHIFT_TIME_ERROR.OVERLAP;
    }
    return CUSTOM_SHIFT_TIME_ERROR.NONE;
}

const checkOverlapShiftTimes = (startTime: string, endTime: string, selectedShiftTimes: ShiftTime[], scheduleDate: string): boolean => {
    const {convertedStartTime, convertedEndTime} = convertTimeRangeToTimestamp(startTime, endTime, scheduleDate);
    const customStartTime = moment.utc(convertedStartTime);
    const customEndTime = moment.utc(convertedEndTime);
    if (selectedShiftTimes?.length > 0) {
        for (let i = 0; i < selectedShiftTimes.length; i++) {
            const shiftTime: ShiftTime = selectedShiftTimes[i];
            const existingStartTime = moment(shiftTime.startTime);
            const existingEndTime = moment(shiftTime.endTime);
            const isCustomStartTimeBetweenExistingShift: boolean = customStartTime.isBetween(existingStartTime, existingEndTime);
            const isCustomEndTimeBetweenExistingShift: boolean = customEndTime.isBetween(existingStartTime, existingEndTime);
            const isExistingStartTimeBetweenCustomShift: boolean = existingStartTime.isBetween(customStartTime, customEndTime);
            const isExistingEndTimeBetweenCustomShift: boolean = existingEndTime.isBetween(customStartTime, customEndTime);
            if ((customStartTime === existingStartTime && customEndTime === existingEndTime)
                || isCustomStartTimeBetweenExistingShift
                || isCustomEndTimeBetweenExistingShift
                || isExistingStartTimeBetweenCustomShift
                || isExistingEndTimeBetweenCustomShift
            ) {
                return true;
            }
        }
    }
    return false;
}

const convertTimeRangeToTimestamp = (startTime: string, endTime: string, baseDate: string) => {
    // convert start time
    const formattedStartTime = moment(startTime, DEFAULT_TIME_ITEM_FORMAT);
    const startHour: number = formattedStartTime.hour();
    const startMinute: number = formattedStartTime.minute();
    const startDate = moment.utc(baseDate ?? '');
    startDate.hour(startHour);
    startDate.minute(startMinute);
    // convert end time
    const formattedEndTime = moment(endTime, DEFAULT_TIME_ITEM_FORMAT);
    const endHour: number = formattedEndTime.hour();
    const endMinute: number = formattedEndTime.minute();
    const endDate = moment.utc(baseDate ?? '');
    endDate.hour(endHour);
    endDate.minute(endMinute);
    return {
        convertedStartTime: startDate.valueOf(),
        convertedEndTime: endDate.valueOf(),
    };
}

const getSelectedShiftTime = (shiftIdSelectionCheck: ShiftIdSelectionCheck, shiftStoreList: ShiftStoreList): ShiftTime[] => {
    if (!!shiftIdSelectionCheck && !!shiftStoreList) {
        const shiftTimeMap: ShiftTimeMap = {};
        [...(shiftStoreList?.standardShifts ?? []), ...(shiftStoreList?.customShifts ?? [])].forEach((shift: Shift) => {
            shiftTimeMap[shift.id] = {
                startTime: shift.startTime,
                endTime: shift.endTime,
            } as ShiftTime;
        });
        return Object.keys(shiftIdSelectionCheck)
            .filter((shiftId: string) => shiftIdSelectionCheck[shiftId])
            .map((shiftId: string) => shiftTimeMap[shiftId]);
    }
    return [];
}

const ShiftList = () => {
    const {
        currentDisplayedShifts,
        setCurrentDisplayedShifts,
        selectedScheduleId,
        shiftStore,
        setShiftStore,
        scheduleMap,
        selectedShiftIdMap,
        setSelectedShiftIdMap,
    } = useShiftContext();
    const {submission, editingShifts} = useSubmissionContext();
    const [isOpenCustomShiftDialog, setOpenCustomShiftDialog] = useState<boolean>(false);
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const isSmScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.MD });
    const selectedSchedule: Schedule = scheduleMap[selectedScheduleId];
    const [customShiftTimeError, setCustomShiftTimeError] = useState<CUSTOM_SHIFT_TIME_ERROR>(CUSTOM_SHIFT_TIME_ERROR.NONE);
    const isBoothCaptainOnElectionDayAccepted: boolean = !!submission?.isBoothCaptainAccepted && selectedSchedule?.dayTypeId === DAY_TYPE_ID.ELECTION_DAY;
    const {height: windowHeight} = useWindowSize();
    const {headerHeight: rightDrawerHeaderHeight} = useRightDrawerContext();
    const [listHeight, setListHeight] = useState<number>(0);
    const [customShiftContainerRef, { height: customShiftContainerHeight }] = useElementSize();
    const {saveShiftButtonHeight} = useShiftMobileContext();
    const [shiftTileRef, { height: shiftTileHeight }] = useElementSize();
    const [isDisplayMobileScrollbarPadding, setDisplayMobileScrollbarPadding] = useState<boolean>(false);
    const {shiftSectionHeight, shiftSectionHeaderHeight} = useShiftSectionContext();

    useEffect(() => {
        if (isXsScreen && !!windowHeight && !!rightDrawerHeaderHeight && !!saveShiftButtonHeight) {
            setListHeight(windowHeight - (
                rightDrawerHeaderHeight + RIGHT_DRAWER_PADDING_TOP + RIGHT_DRAWER_HEADER_MARGIN_BOTTOM
                + customShiftContainerHeight + (!!customShiftContainerHeight ? CUSTOM_SHIFT_MESSAGE_MARGIN_TOP : 0)
                + saveShiftButtonHeight + SAVE_SHIFT_BUTTON_MARGIN_BOTTOM
            ));
        }
    }, [isXsScreen, windowHeight, rightDrawerHeaderHeight, customShiftContainerHeight, saveShiftButtonHeight]);

    useEffect(() => {
        if (isSmScreen  && !!shiftSectionHeight && !!shiftSectionHeaderHeight) {
            setListHeight(shiftSectionHeight - (
                SM_SHIFT_SECTION_MARGIN_Y
                + shiftSectionHeaderHeight + MD_SHIFT_SECTION_HEADER_MARGIN_BOTTOM
                + customShiftContainerHeight + (!!customShiftContainerHeight ? CUSTOM_SHIFT_MESSAGE_MARGIN_TOP : 0)
            ));
        } else if (!isXsScreen && !!shiftSectionHeight && !!shiftSectionHeaderHeight) {
            setListHeight(shiftSectionHeight - (
                MD_SHIFT_SECTION_MARGIN_TOP + MD_SHIFT_SECTION_MARGIN_BOTTOM
                + shiftSectionHeaderHeight + MD_SHIFT_SECTION_HEADER_MARGIN_BOTTOM
                + customShiftContainerHeight
            ));
        }
    }, [isXsScreen, shiftSectionHeight, customShiftContainerHeight, shiftSectionHeaderHeight]);

    useEffect(() => {
        if (!!shiftTileHeight) {
            const currentDisplayedShiftLength: number = currentDisplayedShifts?.length ?? 0;
            const actualListHeight: number = (shiftTileHeight + SHIFT_TILE_PADDING_Y + SHIFT_TILE_MARGIN_BOTTOM) * currentDisplayedShiftLength;
            setDisplayMobileScrollbarPadding(actualListHeight > listHeight);
        }
    }, [listHeight, shiftTileHeight, currentDisplayedShifts]);

    const handleSetCustomButtonClick = () => {
        setCustomShiftTimeError(CUSTOM_SHIFT_TIME_ERROR.NONE);
        setOpenCustomShiftDialog(true);
    }

    const handleSaveShiftButtonClick = (startTime: string, endTime: string) => {
        const selectedShiftTimes: ShiftTime[] = getSelectedShiftTime(selectedShiftIdMap[selectedScheduleId], shiftStore[selectedScheduleId]);
        const customShiftError: CUSTOM_SHIFT_TIME_ERROR = getCustomShiftTimeError(startTime, endTime, selectedShiftTimes, selectedSchedule?.date);
        if (customShiftError === CUSTOM_SHIFT_TIME_ERROR.NONE) {
            const {convertedStartTime, convertedEndTime} = convertTimeRangeToTimestamp(startTime, endTime, selectedSchedule?.date);
            const existingStandardShifts: Shift[] = [...(shiftStore?.[selectedScheduleId]?.standardShifts ?? [])];
            const newCustomShift: Shift = {
                id: ObjectUtil.generateRandomUuid(),
                startTime: convertedStartTime,
                endTime: convertedEndTime,
                isAvailable: true,
                type: SHIFT_TYPE.CUSTOM,
                role: SHIFT_ROLE.VOLUNTEER,
                scheduleId: selectedScheduleId,
            }
            const existingCustomShifts: Shift[] = [...(shiftStore?.[selectedScheduleId]?.customShifts ?? [])];
            existingCustomShifts.push(newCustomShift);
            setCurrentDisplayedShifts([...existingStandardShifts, ...existingCustomShifts]);
            const newShiftStore = {
                ...shiftStore,
                [selectedScheduleId]: {
                    standardShifts: [...(shiftStore?.[selectedScheduleId]?.standardShifts ?? [])],
                    customShifts: existingCustomShifts,
                },
            };
            LocalStorageUtil.setStoredShiftStore(newShiftStore);
            setShiftStore(newShiftStore);
            setOpenCustomShiftDialog(false);
            handleSelectShift(selectedScheduleId, newCustomShift.id);
        }
        setCustomShiftTimeError(customShiftError);
    }

    const handleSelectShift = (scheduleId: string, shiftId: string) => {
        setSelectedShiftIdMap({
            ...selectedShiftIdMap,
            [scheduleId]: {
                ...selectedShiftIdMap?.[scheduleId],
                [shiftId]: !selectedShiftIdMap?.[scheduleId]?.[shiftId],
            }
        });
    }

    const isContainMyShift = (shiftId: string) => {
       return editingShifts.includes(shiftId)
    }

    return (
        <div className="md:flex md:flex-col md:justify-between">
            <div style={{height: `${NumberUtil.convertPxToRem(listHeight)}rem`}}
                 className={`overflow-y-auto ${isDisplayMobileScrollbarPadding ? 'pr-3' : ''} ${styles.shiftListContent}`}>
                {currentDisplayedShifts?.map((shift: Shift) => {
                    const { isAvailable } = shift;
                    if(isAvailable || (!isAvailable && isContainMyShift(shift.id))){
                        return <ShiftTile containerRef={shiftTileRef} key={shift.id} shift={shift} />
                    }
                })}
                {currentDisplayedShifts?.length === 0 && (<div className="text-center">No shift found</div>)}
            </div>
            {
                (!isBoothCaptainOnElectionDayAccepted && (
                    <div ref={customShiftContainerRef}>
                        <div className="text-[#67777F] mt-8">Only have an hour ?</div>
                        <button className="w-full md:w-auto font-extrabold uppercase text-sm border p-3 mb-8 mt-4 md:mb-0" onClick={handleSetCustomButtonClick}>
                            Set Custom
                        </button>
                    </div>
                ))
            }
            <CustomShiftDialog isOpen={isOpenCustomShiftDialog}
                               setOpen={setOpenCustomShiftDialog}
                               timeError={customShiftTimeError}
                               setTimeError={setCustomShiftTimeError}
                               onSaveShift={handleSaveShiftButtonClick} />
        </div>
    );
}

export default ShiftList;
