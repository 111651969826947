import { useSubmissionContext } from '../../contexts/Submission.context';
import { ROUTE_PATH } from '../../constants/RoutePath.constant';
import { useNavigate } from 'react-router-dom';
import StepInfo from '../../partials/StepInfo';
import Footer from '../../partials/Footer';
import NextButton from '../../partials/NextButton';
import { Submission } from '../../models/Submission.model';
import { LocalStorageUtil } from '../../utils/LocalStorage.util';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../constants/Breakpoint.constant';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { NumberUtil } from '../../utils/Number.util';

const HEADER_MARGIN_TOP: number = 24;

const BoothCaptainAgreementPage = () => {
    const { submission, setSubmission } = useSubmissionContext();
    const navigate = useNavigate();
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const {height: windowHeight} = useWindowSize();
    const [headerRef, { height: headerHeight }] = useElementSize();
    const [footerRef, { height: footerHeight }] = useElementSize();
    const [bodyHeight, setBodyHeight] = useState<number>(0);

    useEffect(() => {
        const storeSubmission: Submission = LocalStorageUtil.getStoredSubmission();
        if (storeSubmission.nationBuilderId) {
            setSubmission(storeSubmission);
        }
    }, []);

    useEffect(() => {
        setBodyHeight(windowHeight - (headerHeight + HEADER_MARGIN_TOP + footerHeight));
    }, [windowHeight, headerHeight, footerHeight]);

    const handleBoothCaptainAgreementClick = (isBoothCaptainAccepted: boolean) => {
        const updatedSubmission: Submission = {
            ...submission,
            isBoothCaptainAccepted,
        };
        LocalStorageUtil.setStoredSubmission(updatedSubmission);
        setSubmission(updatedSubmission);
        navigate(`/${ROUTE_PATH.LOCATION_SELECTION}`);
    }

    return (
        <section className="bg-white flex flex-col justify-between md:overflow-hidden">
            <div className="mx-4 my-6 md:m-8 lg:m-10">
                <div ref={headerRef}>
                    <StepInfo stepNumber={1} />
                    <h2 className="font-extrabold uppercase text-[2rem] leading-[120%] mb-6">
                        Would you like to sign up as a ‘Booth Captain’?
                    </h2>
                </div>
                <div style={{height: isXsScreen ? `${NumberUtil.convertPxToRem(bodyHeight)}rem` : 'auto'}} className="overflow-y-auto">
                    <p className="text-lg text-[#67777F]">
                        A booth captain is required for the whole polling day and is in charge of managing the logistics of the booth, including setting up, packing down and coordinating volunteers throughout the day.
                    </p>
                    <p className="text-lg text-[#67777F] mt-6">
                        Captains will be provided with materials and training.
                    </p>
                </div>
            </div>
            <Footer containerRef={footerRef}>
                <div className="flex justify-between md:justify-end">
                    <NextButton title="Yes" onClick={() => handleBoothCaptainAgreementClick(true)} />
                    <button className="basis-[49%] font-extrabold uppercase text-sm p-3 border border-[#0E5173] bg-white md:basis-auto md:ml-2" onClick={() => handleBoothCaptainAgreementClick(false)}>
                        Not this time
                    </button>
                </div>
            </Footer>
        </section>
    );
}

export default BoothCaptainAgreementPage;
