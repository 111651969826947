import LocationTile from './components/LocationTile';
import styles from './index.module.scss';
import { Spinner } from 'flowbite-react';
import { useLocationContext } from '../../../../contexts/Location.context';
import { DAY_TYPE_ID } from '../../../../../../constants/DayTypeId.constant';
import { LocationInfo } from '../../../../../../models/LocationInfo.model';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../../../../../constants/Breakpoint.constant';
import {
    useLocationSelectionMobileContext
} from '../../../LocationSelectionMobile/contexts/LocationSelectionMobile.context';
import { useRightDrawerContext } from '../../../../../../partials/RightDrawer/contexts/RightDrawer.context';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import { NumberUtil } from '../../../../../../utils/Number.util';
import { RIGHT_DRAWER_HEADER_MARGIN_BOTTOM, RIGHT_DRAWER_PADDING_TOP } from '../../../../../../partials/RightDrawer';
import { useContentLayoutContext } from '../../../../../../partials/ContentLayout/contexts/ContentLayout.context';
import { useLocationSectionContext } from '../../contexts/LocationSection.context';

type LocationListProps = {
    locationDayTypeId: DAY_TYPE_ID;
    locationInfos: LocationInfo[];
    locationSize: number;
    defaultLocationSize: number;
    isTwoColumnList?: boolean | undefined;
    onViewMoreLocationClick: (dayTypeId: DAY_TYPE_ID, newLocationSize: number) => void;
}

const MAX_EACH_VIEW_MORE_LOAD: number = 10;
const VIEW_MOVE_LOCATION_MARGIN_TOP: number = 16;
const VIEW_MOVE_LOCATION_MARGIN_BOTTOM: number = 12;
const SAVE_LOCATION_BUTTON_MARGIN_BOTTOM: number = 12;
const LOCATION_TILE_PADDING_Y: number = 48;
const LOCATION_TILE_MARGIN_BOTTOM: number = 16;
const MD_LOCATION_SECTION_PADDING_TOP: number = 24;
const MD_LOCATION_SECTION_PADDING_BOTTOM: number = 32;
const MD_EVENT_DESCRIPTION_MARGIN_BOTTOM: number = 24;
const MD_VIEW_MORE_BUTTON_MARGIN_TOP: number = 28;
const SM_LOCATION_SECTION_MARGIN_Y: number = 16 * 2;

const getDisplayedRemainingTotals = (
    locationTotals: number,
    currentLocationSize: number,
): number => {
    const actualRemainingTotals = locationTotals - currentLocationSize;
    return actualRemainingTotals >= MAX_EACH_VIEW_MORE_LOAD ? MAX_EACH_VIEW_MORE_LOAD : actualRemainingTotals;
}

const LocationList = (
    {
        locationDayTypeId,
        locationInfos,
        locationSize,
        isTwoColumnList,
        onViewMoreLocationClick,
        defaultLocationSize,
    }: LocationListProps) => {
    const { isPrePollingLocationLoading, isElectionLocationLoading } = useLocationContext();
    const isLocationLoading: boolean = locationDayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS
        ? isPrePollingLocationLoading
        : locationDayTypeId === DAY_TYPE_ID.ELECTION_DAY ? isElectionLocationLoading : false;
    const locationDayType: DAY_TYPE_ID = locationInfos?.[0]?.eventType ?? DAY_TYPE_ID.NONE;
    const locationTotals: number = locationInfos?.[0]?.totals ?? 0;
    const displayedRemainingTotals: number = getDisplayedRemainingTotals(locationTotals, locationSize);
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const isSmScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.MD });
    const {height: windowHeight} = useWindowSize();
    const {headerHeight: rightDrawerHeaderHeight} = useRightDrawerContext();
    const [listHeight, setListHeight] = useState<number>(0);
    const [viewMoreLocationButtonRef, { height: viewMoreLocationButtonHeight }] = useElementSize();
    const {saveLocationButtonHeight} = useLocationSelectionMobileContext();
    const [locationTileRef, { height: locationTileHeight }] = useElementSize();
    const [isDisplayMobileScrollbarPadding, setDisplayMobileScrollbarPadding] = useState<boolean>(false);
    const {eventDescriptionHeight} = useLocationSectionContext();
    const {bodyHeight: contentLayoutBodyHeight} = useContentLayoutContext();

    useEffect(() => {
        if (isXsScreen) {
            if (!!windowHeight && !!rightDrawerHeaderHeight && !!viewMoreLocationButtonHeight && !!saveLocationButtonHeight) {
                setListHeight(windowHeight - (
                    rightDrawerHeaderHeight + RIGHT_DRAWER_PADDING_TOP + RIGHT_DRAWER_HEADER_MARGIN_BOTTOM
                    + viewMoreLocationButtonHeight + VIEW_MOVE_LOCATION_MARGIN_TOP + VIEW_MOVE_LOCATION_MARGIN_BOTTOM
                    + saveLocationButtonHeight + SAVE_LOCATION_BUTTON_MARGIN_BOTTOM
                ));
            }
        }
    }, [isXsScreen, windowHeight, rightDrawerHeaderHeight, viewMoreLocationButtonHeight, saveLocationButtonHeight]);

    useEffect(() => {
        if (isSmScreen) {
            if (!!contentLayoutBodyHeight && !!eventDescriptionHeight && !!viewMoreLocationButtonHeight) {
                setListHeight(contentLayoutBodyHeight - (
                    SM_LOCATION_SECTION_MARGIN_Y +
                    eventDescriptionHeight + MD_EVENT_DESCRIPTION_MARGIN_BOTTOM +
                    viewMoreLocationButtonHeight + MD_VIEW_MORE_BUTTON_MARGIN_TOP
                ));
            }
        }
        else if (!isXsScreen) {
            if (!!contentLayoutBodyHeight && !!eventDescriptionHeight && !!viewMoreLocationButtonHeight) {
                setListHeight(contentLayoutBodyHeight - (
                    MD_LOCATION_SECTION_PADDING_TOP + MD_LOCATION_SECTION_PADDING_BOTTOM +
                    eventDescriptionHeight + MD_EVENT_DESCRIPTION_MARGIN_BOTTOM +
                    viewMoreLocationButtonHeight + MD_VIEW_MORE_BUTTON_MARGIN_TOP
                ));
            }
        }
    }, [isXsScreen, contentLayoutBodyHeight, eventDescriptionHeight, viewMoreLocationButtonHeight]);

    useEffect(() => {
        if (!!listHeight && !!locationTileHeight) {
            const locationListLength: number = locationInfos?.length ?? 0;
            const actualListHeight: number = (locationTileHeight + LOCATION_TILE_PADDING_Y + LOCATION_TILE_MARGIN_BOTTOM) * locationListLength;
            setDisplayMobileScrollbarPadding(actualListHeight > listHeight);
        }
    }, [listHeight, locationTileHeight]);

    const handleViewMoreLocationCLick = () => {
        onViewMoreLocationClick(locationDayType, locationSize + displayedRemainingTotals);
    }

    return (
        <div>
            <div style={{height: `${NumberUtil.convertPxToRem(listHeight)}rem`}}
                 className={`flex flex-wrap ${locationInfos?.length > 0 && !isLocationLoading ? 'justify-between content-start' : 'justify-center items-center'} overflow-y-auto ${styles.locationListContent} ${isDisplayMobileScrollbarPadding ? 'pr-3' : ''} md:pr-3`}>
                {isLocationLoading && (
                    <Spinner
                        color="failure"
                        aria-label="Location list is loading"
                    />
                )}
                {!isLocationLoading && (
                    <>
                        {locationInfos?.length > 0 && locationInfos.map((locationInfo: LocationInfo) => (
                            <LocationTile containerRef={locationTileRef}
                                          key={locationInfo.locationID}
                                          locationInfo={locationInfo}
                                          className={isTwoColumnList ? 'basis-[49%]' : 'basis-full'} />
                        ))}
                        {locationInfos?.length === 0 && (
                            <div className="basis-full text-center text-[#67777F]">No Location Found</div>
                        )}
                    </>
                )}
            </div>
            <div ref={viewMoreLocationButtonRef} className={`text-center text-primary-orange font-bold underline underline-offset-8 mt-4 md:mt-7 mb-3 md:mb-0 cursor-pointer`}
                 onClick={handleViewMoreLocationCLick}>
                {`View More Locations`}
            </div>
        </div>
    )
}

export default LocationList;
