import { serviceInstance } from './ServiceInstance';
import { Contact } from '../models/Contact.model';
import { StatusCodes } from 'http-status-codes';

const getContactByNationalBuilderId = (nationBuilderId: string, dayTypeId: string, code = ""): Promise<Contact> => {
    return serviceInstance.get(`/contacts/getcontactbynbid/${nationBuilderId}`, {params: {
            dayType: dayTypeId,
            code
    }}).then((response: any) => response?.status === StatusCodes.OK ? response.data : []);
}

export const ContactService = {
    getContactByNationalBuilderId,
}
