import DialogLayout from '../DialogLayout';

type NationBuilderErrorDialogProps = {
    message: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onYesButtonClick: () => void;
}

const ExistingSubmissionWithoutCodeErrorDialog = (
    {
        message,
        isOpen,
        setOpen,
        onYesButtonClick,
    }: NationBuilderErrorDialogProps) => {
    const title = `You've already booked your shifts`;

    const handleYesButtonClick = () => {
        onYesButtonClick();
        setOpen(false);
    }

    return (
        <DialogLayout title={title} isOpen={isOpen} setOpen={setOpen}>
            <div>
                <p className="px-4 text[#00344B] text-base">
                    {message}
                </p>
                <p className="px-4 text[#00344B] text-base mt-8">
                    If you can't find the link, please email us: <a href={"mailto:info@volunteerforno.com.au"}>info@volunteerforno.com.au</a>
                </p>
                <div>
                    <button className="w-full text-sm py-3 px-4 text-white bg-primary-orange font-extrabold mt-8 uppercase" onClick={handleYesButtonClick}>
                       OK
                    </button>
                </div>
            </div>
        </DialogLayout>
    );
}

export default ExistingSubmissionWithoutCodeErrorDialog;
