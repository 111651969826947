import { createContext, useContext } from 'react';

export type LocationSectionContextProp = {
    eventDescriptionHeight: number,
}

export const LocationSectionContext = createContext<LocationSectionContextProp>({
    eventDescriptionHeight: 0,
})

export const useLocationSectionContext = () => useContext(LocationSectionContext);
