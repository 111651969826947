import { DAY_TYPE_ID } from '../../../../constants/DayTypeId.constant';
import LocationSection from '../LocationSection';
import { LOCATION_CONSTANT } from '../../../../constants/Location.constant';
import LocationList from '../LocationSection/components/LocationList';
import { LocationInfo } from '../../../../models/LocationInfo.model';
import { DEFAULT_NUMBER } from '../../../../constants/Number.constant';
import { useLocationContext } from '../../contexts/Location.context';
import LocationLoadingAnimation from '../LocationLoadingAnimation';

type LocationSelectionMediumProps = {
    selectedDateTypeId: DAY_TYPE_ID;
    prePollingLocationInfos: LocationInfo[];
    electionLocationInfos: LocationInfo[];
    prePollingLocationSize: number;
    electionLocationSize: number;
    onViewMoreLocationClick: (dayTypeId: DAY_TYPE_ID, newLocationSize: number) => void;
}

const LocationSelectionMedium = (
    {
        selectedDateTypeId,
        prePollingLocationInfos,
        electionLocationInfos,
        prePollingLocationSize,
        electionLocationSize,
        onViewMoreLocationClick,
    }: LocationSelectionMediumProps
) => {
    const defaultLocationSize = selectedDateTypeId === DAY_TYPE_ID.BOTH ? DEFAULT_NUMBER.BOTH_DAY_TYPE_LOCATION_SIZE : DEFAULT_NUMBER.SINGLE_DAY_TYPE_LOCATION_SIZE;
    const { isPrePollingLocationLoading, isElectionLocationLoading } = useLocationContext();
    const isRequestingLocation: boolean = (isPrePollingLocationLoading || isElectionLocationLoading)
        && (prePollingLocationInfos?.length === 0 && electionLocationInfos?.length === 0);

    return (
        <div>
            {isRequestingLocation && (
                <div className="h-[28rem] flex flex-col justify-center items-center">
                    <LocationLoadingAnimation />
                </div>
            )}
            {!isRequestingLocation && selectedDateTypeId !== DAY_TYPE_ID.BOTH && (
                <div>
                    <LocationSection listTitle={selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? LOCATION_CONSTANT.PRE_POLLING_SECTION_TITLE : LOCATION_CONSTANT.ELECTION_SECTION_TITLE}
                                     locationInfos={selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? prePollingLocationInfos : electionLocationInfos}>
                        <LocationList locationDayTypeId={selectedDateTypeId}
                                      locationInfos={selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? prePollingLocationInfos : electionLocationInfos}
                                      locationSize={selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? prePollingLocationSize : electionLocationSize}
                                      onViewMoreLocationClick={onViewMoreLocationClick}
                                      isTwoColumnList={true}
                                      defaultLocationSize={defaultLocationSize} />
                    </LocationSection>
                </div>
            )}
            {!isRequestingLocation && selectedDateTypeId === DAY_TYPE_ID.BOTH && (
                <div className="flex h-full">
                    <LocationSection listTitle={LOCATION_CONSTANT.PRE_POLLING_SECTION_TITLE}
                                     locationInfos={prePollingLocationInfos}
                                     className="basis-2/4">
                        <LocationList locationDayTypeId={DAY_TYPE_ID.PRE_POLLING_DAYS}
                                      locationInfos={prePollingLocationInfos}
                                      locationSize={prePollingLocationSize}
                                      onViewMoreLocationClick={onViewMoreLocationClick}
                                      defaultLocationSize={defaultLocationSize} />
                    </LocationSection>
                    <LocationSection listTitle={LOCATION_CONSTANT.ELECTION_SECTION_TITLE}
                                     locationInfos={electionLocationInfos}
                                     className="basis-2/4 border-l border-[#D5DADD]">
                        <LocationList locationDayTypeId={DAY_TYPE_ID.ELECTION_DAY}
                                      locationInfos={electionLocationInfos}
                                      locationSize={electionLocationSize}
                                      onViewMoreLocationClick={onViewMoreLocationClick}
                                      defaultLocationSize={defaultLocationSize} />
                    </LocationSection>
                </div>
            )}
        </div>
    );
}

export default LocationSelectionMedium;
