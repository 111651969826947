import { createContext, useContext } from 'react';
import { Submission } from '../models/Submission.model';

export type SubmissionContextProp = {
    submission: Submission,
    setSubmission: (submission: Submission) => void,
    submissionCode: string,
    setSubmissionCode: (code: string) => void;
    editingShifts: string[],
    setEditingShifts: (shifts: string[]) => void;
}

export const SubmissionContext = createContext<SubmissionContextProp>({
    submission: {} as Submission,
    setSubmission: () => {},
    submissionCode: "",
    setSubmissionCode: (code) => {},
    editingShifts: [],
    setEditingShifts: (shifts) => {}
})

export const useSubmissionContext = () => useContext(SubmissionContext);
