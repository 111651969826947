import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className="md:h-screen print:h-max md:print:h-max md:flex md:flex-col">
            <div className="md:flex md:flex-col md:justify-center">
                <Outlet />
            </div>
        </div>
    )
};

export default Layout;
