export enum SHIFT_CONSTANT {
    DEFAULT_START_TIME = 8,
    DEFAULT_SCHEDULE_END_TIME = 8,
    DEFAULT_24_HOURS_FORMATTED_CUSTOM_SHIFT_END_TIME = 18,
    DEFAULT_CUSTOM_SHIFT_END_TIME = 6,
}

export const DEFAULT_TIME_RANGE = `${SHIFT_CONSTANT.DEFAULT_START_TIME}am - ${SHIFT_CONSTANT.DEFAULT_SCHEDULE_END_TIME}pm`;
export const DEFAULT_CUSTOM_SHIFT_TIME_RANGE = `${SHIFT_CONSTANT.DEFAULT_START_TIME}am - ${SHIFT_CONSTANT.DEFAULT_CUSTOM_SHIFT_END_TIME}pm`;

export enum SHIFT_TYPE {
    STANDARD = 'standard',
    CUSTOM = 'custom',
}

export enum SHIFT_ROLE_NAME {
    BOOTH_CAPTAIN = 'Booth Captain',
    SCRUTINEERING = 'Scrutineering',
    VOLUNTEER = 'Volunteer',
}

export enum SHIFT_ROLE {
    BOOTH_CAPTAIN = 'boothcaptain',
    SCRUTINEER = 'scrutineer',
    VOLUNTEER = 'volunteer',
}
