import ClockIcon from '../../../../../../../../svgs/ClockIcon';
import ArrowDownIcon from '../../../../../../../../svgs/ArrowDownIcon';
import { useMemo, useState } from 'react';
import { SHIFT_CONSTANT } from '../../../../../../../../constants/Shift.constant';
import moment from 'moment';
import styles from './index.module.scss';
import { TIME_FORMAT } from '../../../../../../../../constants/Time.constant';

type TimeDropdownProps = {
    label: string;
    onChange: (value: string) => void;
}

export const DEFAULT_TIME_ITEM_FORMAT: string = TIME_FORMAT.HOUR_12;

const buildLabel = (label: string) => {
    return (
        <div className="flex justify-between items-center">
            <div className="flex items-center">
                <ClockIcon />
                <span className="ml-2 text-[#67777F] font-normal text-sm">{label}</span>
            </div>
            <ArrowDownIcon />
        </div>
    )
}

const getTimeList = (): string[] => {
    const currentDate = new Date();
    let startDate: number = currentDate.setHours(SHIFT_CONSTANT.DEFAULT_START_TIME, 0 , 0 , 0);
    const endDate: number = currentDate.setHours(SHIFT_CONSTANT.DEFAULT_24_HOURS_FORMATTED_CUSTOM_SHIFT_END_TIME, 0 , 0 , 0);
    const timeList: string[] = [moment(startDate).format(DEFAULT_TIME_ITEM_FORMAT)];
    while (startDate !== endDate) {
        const nextTimeItem = moment(startDate).add(30, 'minutes');
        timeList.push(nextTimeItem.format(DEFAULT_TIME_ITEM_FORMAT));
        startDate = nextTimeItem.valueOf();
    }
    return timeList;
}

const TimeDropdown = ({label, onChange}: TimeDropdownProps) => {
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const timeList: string[] = useMemo(() => getTimeList(), []);
    const [selectedTime, setSelectedTime] = useState<string>('');

    const handleDropdownButtonClick = () => {
        setExpanded(!isExpanded);
    }

    const handleTimeClick = (timeItem: string) => {
        setExpanded(false);
        setSelectedTime(timeItem);
        onChange(timeItem);
    }

    return (
        <div className="mb-4 last:mb-0 md:basis-[48%]">
            <button className="text-white bg-white border border-[#D5DADD] py-2 px-2.5 w-full"
                    type="button"
                    onClick={handleDropdownButtonClick}>
                {selectedTime ? buildLabel(selectedTime) : buildLabel(label)}
            </button>
            {isExpanded && (
                <div className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-[91%] dark:bg-gray-700 dark:divide-gray-600 font-normal h-36 overflow-y-auto md:w-[36%] lg:w-[36.5%] ${styles.timeListContent}`}>
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDividerButton">
                        <li>
                            <a onClick={() => handleTimeClick('')}
                               className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
                                Select {label}
                            </a>
                        </li>
                        {timeList.map((timeItem: string) => (
                            <li key={timeItem}>
                                <a onClick={() => handleTimeClick(timeItem)}
                                   className={`${timeItem === selectedTime ? 'bg-gray-200' : ''} block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer`}>
                                    {timeItem}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default TimeDropdown;
