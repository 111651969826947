import DialogLayout from '../../../../partials/DialogLayout';
import NextButton from '../../../../partials/NextButton';

type MissingPrePollShiftNotificationDialogProps = {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onSkip: () => void;
}

const MissingPrePollShiftNotificationDialog = (
    {
        isOpen,
        setOpen,
        onSkip,
    }: MissingPrePollShiftNotificationDialogProps
) => {

    const handleCloseButtonClick = () => {
        setOpen(false);
    }

    const handleSkipButtonClick = () => {
        onSkip();
    }

    return (
        <DialogLayout title="Pre-poll shift not selected" isOpen={isOpen} setOpen={setOpen}>
            <div>
                <p className="px-4 text[#00344B] text-base">
                    Please select at least on pre-poll day in the list and then choose at least one available shift that works for you
                </p>
                <div className="flex justify-between mt-8">
                    <button className="basis-[49%] w-full text-sm py-3 px-3 bg-white font-extrabold uppercase border" onClick={handleCloseButtonClick}>
                        CLOSE
                    </button>
                    <NextButton additionalClassName="!basis-[49%]" title="SKIP" onClick={handleSkipButtonClick} />
                </div>
            </div>
        </DialogLayout>
    );
}

export default MissingPrePollShiftNotificationDialog;
