import DialogLayout from '../../../../../../partials/DialogLayout';
import NextButton from '../../../../../../partials/NextButton';
import TimeDropdown from './components/TimeDropdown';
import { DEFAULT_CUSTOM_SHIFT_TIME_RANGE } from '../../../../../../constants/Shift.constant';
import { useEffect, useState } from 'react';
import { CUSTOM_SHIFT_TIME_ERROR } from '../../index';

type CustomShiftDialogProps = {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onSaveShift: (startTime: string, endTime: string) => void;
    timeError: CUSTOM_SHIFT_TIME_ERROR;
    setTimeError: (timeError: CUSTOM_SHIFT_TIME_ERROR) => void;
}

const getErrorMessage = (timeError: CUSTOM_SHIFT_TIME_ERROR): string => {
    switch (timeError) {
        case CUSTOM_SHIFT_TIME_ERROR.INVALID_RANGE:
            return 'Invalid time';
        case CUSTOM_SHIFT_TIME_ERROR.MORE_THAN_150_MINUTES:
            return 'Cannot be longer than 2.5 hours';
        case CUSTOM_SHIFT_TIME_ERROR.OVERLAP:
            return 'Time clashes with existing selected shift(s)';
        default:
            return '';
    }
}

const CustomShiftDialog = ({isOpen, setOpen, onSaveShift, timeError, setTimeError}: CustomShiftDialogProps) => {
    const [selectedStartTime, setSelectedStartTime] = useState<string>('');
    const [selectedEndTime, setSelectedEndTime] = useState<string>('');
    const isInvalidTime: boolean = timeError !== CUSTOM_SHIFT_TIME_ERROR.NONE;
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        setErrorMessage(getErrorMessage(timeError));
    }, [timeError]);

    const handleStartDateChange = (timeItem: string) => {
        setSelectedStartTime(timeItem);
        if (isInvalidTime) {
            setTimeError(CUSTOM_SHIFT_TIME_ERROR.NONE);
        }
    }

    const handleEndDateChange = (timeItem: string) => {
        setSelectedEndTime(timeItem);
        if (isInvalidTime) {
            setTimeError(CUSTOM_SHIFT_TIME_ERROR.NONE);
        }
    }

    const handleSaveButtonClick = () => {
        onSaveShift(selectedStartTime, selectedEndTime);
    }

    return (
        <DialogLayout title="When can you be a volunteer?" isOpen={isOpen} setOpen={setOpen}>
            <div>
                <div className="text-[#67777F] font-normal pb-8">Can be scheduled between {DEFAULT_CUSTOM_SHIFT_TIME_RANGE}</div>
                <div className="md:flex justify-between">
                    <TimeDropdown onChange={handleStartDateChange} label="Start Time" />
                    <TimeDropdown onChange={handleEndDateChange} label="End Time" />
                </div>
                <div className={`text-red-500 ${!!selectedStartTime && !!selectedEndTime && isInvalidTime ? '' : 'invisible'}`}>
                    {errorMessage}
                </div>
                <div className="flex mt-2">
                    <NextButton className="w-full text-white bg-primary-orange" title="Save Shift" onClick={handleSaveButtonClick} />
                </div>
            </div>
        </DialogLayout>
    );
}

export default CustomShiftDialog;
