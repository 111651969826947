import { createContext, useContext } from 'react';

export type RightDrawerProp = {
    headerHeight: number,
}

export const RightDrawerContext = createContext<RightDrawerProp>({
    headerHeight: 0,
})

export const useRightDrawerContext = () => useContext(RightDrawerContext);
