import ShiftSummaryRow from './ShiftSummaryRow';
import { ShiftScheduleGroup, Submission } from '../../../../../../models/Submission.model';
import { Schedule } from '../../../../../../models/Schedule.model';
import { Shift } from '../../../../../../models/Shift.model';
import { useSubmissionContext } from '../../../../../../contexts/Submission.context';
import { DAY_TYPE_ID } from '../../../../../../constants/DayTypeId.constant';
import { LocationInfo } from '../../../../../../models/LocationInfo.model';
import { LocationUtil } from '../../../../../../utils/Location.util';

type ShiftSummaryListProps = {
    shiftScheduleGroup: ShiftScheduleGroup;
}

const getLocationInfo = (scheduleDayType: DAY_TYPE_ID, submission: Submission): LocationInfo => {
    if (scheduleDayType === DAY_TYPE_ID.PRE_POLLING_DAYS) {
        return submission?.selectedPrePollingLocationInfo ?? {} as LocationInfo;
    } else if (scheduleDayType === DAY_TYPE_ID.ELECTION_DAY) {
        return submission?.selectedElectionLocationInfo ?? {} as LocationInfo;
    }
    return {} as LocationInfo;
}

const ShiftSummaryList = ({shiftScheduleGroup}: ShiftSummaryListProps) => {
    const {submission} = useSubmissionContext();
    const schedule: Schedule = shiftScheduleGroup?.schedule ?? {};
    const locationInfo: LocationInfo = getLocationInfo(schedule?.dayTypeId ?? schedule?.eventType ?? DAY_TYPE_ID.NONE, submission);
    const shifts: Shift[] = [...(shiftScheduleGroup?.standardShifts ?? []), ...(shiftScheduleGroup?.customShifts ?? [])];
    const sortedShifts: Shift[] = shifts.sort((shiftA: Shift, shiftB: Shift) => shiftA.endTime - shiftB.endTime);

    return shifts.length > 0 ? (
        <div className="mb-8 last:mb-0">
            <div className="font-semibold text-lg">
                {schedule?.title ?? ''}
            </div>
            <div className="font-semibold">
                {LocationUtil.removePPVCFromLocationName(locationInfo?.locationName ?? '')}
            </div>
            <div className="font-semibold text-[#67777F]">
                {LocationUtil.getStringLocationAddress(locationInfo)}
            </div>
            <div>
                {sortedShifts.map((shift: Shift) => (<ShiftSummaryRow key={shift.id} shift={shift} />))}
            </div>
        </div>
    ) : <></>;
}

export default ShiftSummaryList;
