import LocationSection from '../LocationSection';
import { LocationInfo } from '../../../../models/LocationInfo.model';
import { LOCATION_CONSTANT } from '../../../../constants/Location.constant';
import NextButton from '../../../../partials/NextButton';
import RightDrawer from '../../../../partials/RightDrawer';
import { useEffect, useState } from 'react';
import LocationList from '../LocationSection/components/LocationList';
import { DAY_TYPE_ID } from '../../../../constants/DayTypeId.constant';
import { useLocationContext } from '../../contexts/Location.context';
import LocationLoadingAnimation from '../LocationLoadingAnimation';
import { useElementSize } from 'usehooks-ts';
import { LocationSelectionMobileContext } from './contexts/LocationSelectionMobile.context';
import { LocationUtil } from '../../../../utils/Location.util';

type LocationSelectionMobileProps = {
    defaultLocationSize: number;
    prePollingLocationInfos: LocationInfo[];
    electionLocationInfos: LocationInfo[];
    prePollingLocationSize: number;
    electionLocationSize: number;
    onViewMoreLocationClick: (dayTypeId: DAY_TYPE_ID, newLocationSize: number) => void;
}

const LocationSelectionMobile = (
    {
        defaultLocationSize,
        prePollingLocationInfos,
        electionLocationInfos,
        prePollingLocationSize,
        electionLocationSize,
        onViewMoreLocationClick,
    }: LocationSelectionMobileProps
) => {
    const [isOpenLocationList, setOpenLocationList] = useState<boolean>(false);
    const [selectedLocationInfos, setSelectedLocationInfos] = useState<LocationInfo[]>([]);
    const [selectedLocationSize, setSelectedLocationSize] = useState<number>(0);
    const [currentDateTypeId, setCurrentDateTypeId] = useState<DAY_TYPE_ID>(DAY_TYPE_ID.NONE);
    const { isPrePollingLocationLoading, isElectionLocationLoading, selectedElectionLocationId, selectedPrePollingLocationId } = useLocationContext();
    const [isLocationLoading, setLocationLoading] = useState<boolean>(true);
    const [saveLocationButtonRef, { height: saveLocationButtonHeight }] = useElementSize();

    useEffect(() => {
        if (currentDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
            setSelectedLocationInfos(prePollingLocationInfos);
            setSelectedLocationSize(prePollingLocationSize);
        } else {
            setSelectedLocationInfos(electionLocationInfos);
            setSelectedLocationSize(electionLocationSize);
        }
    }, [prePollingLocationInfos, electionLocationInfos, selectedLocationSize, electionLocationSize]);

    useEffect(() => {
        setLocationLoading(isPrePollingLocationLoading || isElectionLocationLoading);
    }, [isPrePollingLocationLoading, isElectionLocationLoading])

    const handleSelectPrePollingLocationButtonClick = () => {
        setOpenLocationList(true);
        setSelectedLocationInfos(prePollingLocationInfos);
        setSelectedLocationSize(prePollingLocationSize);
        setCurrentDateTypeId(DAY_TYPE_ID.PRE_POLLING_DAYS);
    }

    const handleSelectElectionLocationButtonClick = () => {
        setOpenLocationList(true);
        setSelectedLocationInfos(electionLocationInfos);
        setSelectedLocationSize(electionLocationSize);
        setCurrentDateTypeId(DAY_TYPE_ID.ELECTION_DAY);
    }

    const closeLocationList = () => setOpenLocationList(false);

    const getLocationNameById = (dayTypeId: DAY_TYPE_ID, locationID: string) => {
        const locationInfos: LocationInfo[] = [];
        if (dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
            locationInfos.push(...prePollingLocationInfos);
        } else if (dayTypeId === DAY_TYPE_ID.ELECTION_DAY) {
            locationInfos.push(...electionLocationInfos);
        }
        const locationInfo = locationInfos.find(
            (locationInfo: LocationInfo) => locationInfo.locationID === locationID
        );
        const builtLocation: string = LocationUtil.removePPVCFromLocationName(locationInfo?.locationName ?? '');
        return builtLocation ? builtLocation : 'Select Location';
    }

    return (
        <LocationSelectionMobileContext.Provider value={{saveLocationButtonHeight}}>
            <div>
                {isLocationLoading && (
                    <div className="h-full flex flex-col justify-center items-center mt-10">
                        <LocationLoadingAnimation />
                    </div>
                )}
                {!isLocationLoading && prePollingLocationInfos?.length > 0 && (
                    <LocationSection listTitle={LOCATION_CONSTANT.PRE_POLLING_SECTION_TITLE}
                                     locationInfos={prePollingLocationInfos}>
                        <div className="flex">
                            <NextButton title={getLocationNameById(DAY_TYPE_ID.PRE_POLLING_DAYS, selectedPrePollingLocationId)}
                                        onClick={handleSelectPrePollingLocationButtonClick}
                                        className="w-full border-[1.2px] border-[#D5DADD] hover:border-[#002030] active:border-[#002030]" />
                        </div>
                    </LocationSection>
                )}
                {!isLocationLoading && prePollingLocationInfos?.length > 0 && electionLocationInfos?.length > 0 && (
                    <hr className="m-2 px-4" />
                )}
                {!isLocationLoading && electionLocationInfos?.length > 0 && (
                    <LocationSection listTitle={LOCATION_CONSTANT.ELECTION_SECTION_TITLE}
                                     locationInfos={electionLocationInfos}>
                        <div className="flex">
                            <NextButton title={getLocationNameById(DAY_TYPE_ID.ELECTION_DAY, selectedElectionLocationId)}
                                        onClick={handleSelectElectionLocationButtonClick}
                                        className="w-full border-[1.2px] border-[#D5DADD] hover:border-[#002030] active:border-[#002030]" />
                        </div>
                    </LocationSection>
                )}
                {!isLocationLoading && prePollingLocationInfos?.length === 0 && electionLocationInfos?.length === 0 && (
                    <div className="h-full flex flex-col justify-center items-center">No data found</div>
                )}
                <RightDrawer isOpened={isOpenLocationList}
                             setClose={closeLocationList}
                             title="Select your desired location">
                    <div>
                        <LocationList locationDayTypeId={currentDateTypeId}
                                      defaultLocationSize={defaultLocationSize}
                                      locationInfos={selectedLocationInfos}
                                      locationSize={selectedLocationSize}
                                      onViewMoreLocationClick={onViewMoreLocationClick} />
                        <NextButton containerRef={saveLocationButtonRef} className="w-full text-white bg-primary-orange" title="Save Location" onClick={closeLocationList} />
                    </div>
                </RightDrawer>
            </div>
        </LocationSelectionMobileContext.Provider>
    );
}

export default LocationSelectionMobile;
