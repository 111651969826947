import { Card } from 'flowbite-react';
import { Shift } from '../../../../../../models/Shift.model';
import TileCheckIcon from '../../../../../../svgs/TileCheckIcon';
import moment from 'moment/moment';
import { SHIFT_ROLE, SHIFT_ROLE_NAME } from '../../../../../../constants/Shift.constant';
import { useShiftContext } from '../../../../contexts/Shift.context';
import TileUncheckIcon from '../../../../../../svgs/TileUncheckIcon';
import { ShiftUtil } from '../../../../../../utils/Shift.util';
import { TIME_FORMAT } from '../../../../../../constants/Time.constant';
import { useSubmissionContext } from '../../../../../../contexts/Submission.context';

type ShiftTileProps = {
    containerRef?: any;
    shift: Shift;
}

const ShiftTile = ({containerRef, shift}: ShiftTileProps) => {
    const {submission} = useSubmissionContext();
    const startTime: string = shift?.startTime ? moment.utc(shift.startTime).format(TIME_FORMAT.HOUR_12) : '';
    const endTime: string = shift?.endTime ? moment.utc(shift.endTime).format(TIME_FORMAT.HOUR_12) : '';
    const isShiftAvailable: boolean = !!shift?.isAvailable;
    const shiftId: string = shift?.id ?? '';
    const {selectedShiftIdMap, setSelectedShiftIdMap} = useShiftContext();
    const role: SHIFT_ROLE_NAME = ShiftUtil.getRole(shift?.role);
    const scheduleId: string = shift?.scheduleId;

    const handleShiftTileClick = () => {
        // if (isShiftAvailable) {
            setSelectedShiftIdMap({
                ...selectedShiftIdMap,
                [scheduleId]: {
                    ...selectedShiftIdMap?.[scheduleId],
                    [shiftId]: !selectedShiftIdMap?.[scheduleId]?.[shiftId],
                }
            });
        // }
    }

    return (
        <Card className={`!shadow-0.02 cursor-pointer mb-3 ${shift?.role === SHIFT_ROLE.BOOTH_CAPTAIN ? 'h-40' : ''}`} onClick={handleShiftTileClick}>
            <div ref={containerRef} className="flex items-center justify-between">
                <div className="flex flex-col">
                    <h5 className="text-sm md:text-base font-bold">
                        {`${startTime} - ${endTime}`}
                    </h5>
                    <p className="text-sm md:text-base">
                        {role}
                    </p>
                </div>
                {selectedShiftIdMap?.[scheduleId]?.[shiftId] ? <TileCheckIcon /> : <TileUncheckIcon />}
            </div>
        </Card>
    );
}

export default ShiftTile;
