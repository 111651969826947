const ShiftCalendarIcon = () => {
    return (
        <svg width="154" height="155" viewBox="0 0 154 155" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M125.125 24.5625H28.875C26.2171 24.5625 24.0625 26.7171 24.0625 29.375V125.625C24.0625 128.283 26.2171 130.438 28.875 130.438H125.125C127.783 130.438 129.938 128.283 129.938 125.625V29.375C129.938 26.7171 127.783 24.5625 125.125 24.5625Z" stroke="#EC6E41" strokeWidth="2.32453" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M105.988 14.9941V34.2441" stroke="#EC6E41" strokeWidth="2.32453" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M48.125 14.9375V34.1875" stroke="#EC6E41" strokeWidth="2.32453" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.0625 53.4375H129.938" stroke="#EC6E41" strokeWidth="2.32453" strokeLinecap="round" strokeLinejoin="round"/>
            <rect opacity="0.2" x="34.8691" y="61.5195" width="11.6226" height="11.6226" fill="#EC6E41"/>
            <rect x="58.1143" y="61.5195" width="11.6226" height="11.6226" fill="#03CE54"/>
            <g clipPath="url(#clip0_194_3040)">
                <path d="M60.9277 67.8765L62.8346 69.7833L67.1931 65.4248" stroke="white" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
            </g>
            <rect x="81.3594" y="61.5195" width="11.6226" height="11.6226" fill="#03CE54"/>
            <g clipPath="url(#clip1_194_3040)">
                <path d="M84.1729 67.8765L86.0797 69.7833L90.4382 65.4248" stroke="white" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
            </g>
            <rect opacity="0.2" x="104.605" y="61.5195" width="11.6226" height="11.6226" fill="#EC6E41"/>
            <rect opacity="0.2" x="34.8691" y="84.7646" width="11.6226" height="11.6226" fill="#EC6E41"/>
            <rect x="58.1143" y="84.7646" width="11.6226" height="11.6226" fill="#03CE54"/>
            <g clipPath="url(#clip2_194_3040)">
                <path d="M60.9277 91.1216L62.8346 93.0284L67.1931 88.6699" stroke="white" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
            </g>
            <rect opacity="0.2" x="81.3594" y="84.7646" width="11.6226" height="11.6226" fill="#EC6E41"/>
            <rect x="104.605" y="84.7646" width="11.6226" height="11.6226" fill="#03CE54"/>
            <g clipPath="url(#clip3_194_3040)">
                <path d="M107.419 91.1216L109.326 93.0284L113.684 88.6699" stroke="white" strokeWidth="1.6" strokeLinecap="square" strokeLinejoin="round"/>
            </g>
            <rect opacity="0.2" x="34.8691" y="108.01" width="11.6226" height="11.6226" fill="#EC6E41"/>
            <rect opacity="0.2" x="58.1143" y="108.01" width="11.6226" height="11.6226" fill="#EC6E41"/>
            <defs>
                <clipPath id="clip0_194_3040">
                    <rect width="8.71698" height="8.71698" fill="white" transform="translate(59.5654 62.9736)"/>
                </clipPath>
                <clipPath id="clip1_194_3040">
                    <rect width="8.71698" height="8.71698" fill="white" transform="translate(82.8105 62.9736)"/>
                </clipPath>
                <clipPath id="clip2_194_3040">
                    <rect width="8.71698" height="8.71698" fill="white" transform="translate(59.5654 86.2188)"/>
                </clipPath>
                <clipPath id="clip3_194_3040">
                    <rect width="8.71698" height="8.71698" fill="white" transform="translate(106.057 86.2188)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default ShiftCalendarIcon;
