import { serviceInstance } from './ServiceInstance';
import { Schedule } from '../models/Schedule.model';
import { Shift } from '../models/Shift.model';
import { SubmissionDTO } from '../models/dto/SubmissionDTO.model';
import { StatusCodes } from 'http-status-codes';
import { ShiftRequestDTO } from '../models/dto/ScheduleRequestDTO.model';
import { LocationInfo } from '../models/LocationInfo.model';

const getSchedulesByLocation = (location: LocationInfo): Promise<Schedule[]> => {
    return location.locationID ? serviceInstance.get('/schedules/getbyloc', {params: {
            locationId: location?.locationId ?? location?.detail?.locationId ?? null,
            premisesName: location?.premisesName ?? location?.detail?.premisesName ?? null,
        }}).then((response: any) => {
            return response?.status === StatusCodes.OK ? response?.data ?? [] : [];
    }) : Promise.resolve([]);
}

const getShiftsByScheduleId = (scheduleId: string, params: ShiftRequestDTO): Promise<Shift[]> => {
    return scheduleId ? serviceInstance.get(`/schedules/shifts/getbyschedule/${scheduleId}`, {params}).then((response: any) => {
        return response?.status === StatusCodes.OK ? response?.data ?? [] : [];
    }) : Promise.resolve([]);
}

const postSubmission = (submissionDTO: SubmissionDTO) => {
    return serviceInstance.post('/schedules/submission', submissionDTO).then((response: any) => {
        return response;
    });
}

const removeAllShifts = (nationBuilderId: number | string, submissionCode: string) => {
    return serviceInstance.post('/schedules/remove-all-shifts', {
        nationBuilderId,
        submissionCode,
    }).then((response: any) => {
        return response;
    });
}

export const ScheduleService = {
    getSchedulesByLocation,
    getShiftsByScheduleId,
    postSubmission,
    removeAllShifts
}
