import { Card } from 'flowbite-react';
import { Schedule } from '../../../../../../../../models/Schedule.model';
import TileBadge from '../../../../../../../../partials/TileBadge';
import { ArrowSmallRightIcon } from '@heroicons/react/24/solid';
import { SelectedShiftIdMap, ShiftStore, useShiftContext } from '../../../../../../contexts/Shift.context';
import { useShiftMobileContext } from '../../../../../../contexts/ShiftMobile.context';
import SmallCheckIcon from '../../../../../../../../svgs/SmallCheckIcon';
import { useEffect, useState } from 'react';
import { Shift } from '../../../../../../../../models/Shift.model';
import moment from 'moment';
import { TIME_FORMAT } from '../../../../../../../../constants/Time.constant';

type ScheduleTileProps = {
    schedule: Schedule;
}

const buildSelectShiftMessage = () => {
    return (
        <div className="flex items-center text-sm font-medium">Select shifts <ArrowSmallRightIcon className="h-5 w-5 ml-2 font-thin" /></div>
    );
}

const buildNumberOfSelectedShiftMessage = (numberOfSelectedShifts: number) => {
    return (
        <div className="flex items-center"><SmallCheckIcon /> <div className="ml-1">{numberOfSelectedShifts} shifts selected</div></div>
    );
}

const ScheduleTile = ({schedule}: ScheduleTileProps) => {
    const {shiftStore, selectedScheduleId, setSelectedScheduleId, selectedShiftIdMap, setCurrentDisplayedShifts} = useShiftContext();
    const isScheduleSelected: boolean = selectedScheduleId === schedule?.scheduleId;
    const {setOpenShiftList} = useShiftMobileContext();
    const [numberOfSelectedShifts, setNumberOfSelectedShifts] = useState<number>(0);
    const [timeRange, setTimeRange] = useState<string>('');

    useEffect(() => {
        setNumberOfSelectedShifts(countSelectedShift(selectedShiftIdMap));
    }, [selectedShiftIdMap]);

    useEffect(() => {
        buildTimeRange(shiftStore);
    }, [shiftStore]);

    const buildTimeRange = (shiftStore: ShiftStore) => {
        const standardShifts: Shift[] = shiftStore?.[schedule.scheduleId]?.standardShifts ?? [];
        const firstShiftStartTime: string = standardShifts.length > 0
            ? standardShifts[0]?.startTime
                ? moment.utc(standardShifts[0].startTime).format(TIME_FORMAT.HOUR_12) : '' : '';
        const lastShiftEndTime: string = standardShifts.length > 0
            ? standardShifts[standardShifts.length - 1]?.endTime
                ? moment.utc(standardShifts[standardShifts.length - 1].endTime).format(TIME_FORMAT.HOUR_12) : '' : '';
        setTimeRange([firstShiftStartTime, lastShiftEndTime].join(' - '));
    }

    const handleScheduleTileClick = () => {
        if (selectedScheduleId === schedule?.scheduleId) {
            setSelectedScheduleId('');
            setCurrentDisplayedShifts([]);
            setOpenShiftList(false);
        } else {
            setSelectedScheduleId(schedule?.scheduleId);
            setOpenShiftList(true);
        }
    }

    const countSelectedShift = (selectedShiftIdMap: SelectedShiftIdMap): number => {
        let numberOfShifts = 0;
        const selectedIds = selectedShiftIdMap[schedule?.scheduleId] ?? {};
        for (const shiftId in selectedIds) {
            if (selectedIds[shiftId]) {
                numberOfShifts++;
            }
        }
        return numberOfShifts;
    }

    return (
        <Card className={`!shadow-0.02 text-[#00344B] mb-3 cursor-pointer relative ${isScheduleSelected ? '!bg-[#CFE5FE] !border-[#0555B3]' : ''}`} onClick={handleScheduleTileClick}>
            <TileBadge isDisplayed={isScheduleSelected} message={buildSelectShiftMessage()} priorityClassName="!bg-[#0555B3] !text-white !h-4 !text-xs !normal-case !font-medium lg:hidden" />
            <TileBadge isDisplayed={!isScheduleSelected && numberOfSelectedShifts > 0} message={buildNumberOfSelectedShiftMessage(numberOfSelectedShifts)} priorityClassName="!bg-[#03CE54] !text-white !h-4 !text-xs !normal-case !font-medium lg:hidden" />
            <div className="lg:flex lg:justify-between lg:items-center">
                <div>
                    <h5 className="font-bold whitespace-nowrap">
                        {schedule?.title ?? ''}
                    </h5>
                    <p className="">
                        Shifts available {timeRange}
                    </p>
                </div>
                {isScheduleSelected && (
                    <div className="ml-3 hidden lg:block py-1 px-2 rounded bg-[#0555B3] text-white text-xl">
                        {buildSelectShiftMessage()}
                    </div>
                )}
                {(!isScheduleSelected && numberOfSelectedShifts > 0) && (
                    <div className="hidden lg:block py-1 px-2 rounded bg-[#03CE54] text-white text-sm">
                        {buildNumberOfSelectedShiftMessage(numberOfSelectedShifts)}
                    </div>
                )}
            </div>
        </Card>
    );
}

export default ScheduleTile;
