import { serviceInstance } from './ServiceInstance';
import { DAY_TYPE_ID } from '../constants/DayTypeId.constant';
import { StatusCodes } from 'http-status-codes';

const getAvailableLocations = (
    // longitude: number,
    // latitude: number,
    dayTypeId: DAY_TYPE_ID,
    size: number,
    nationBuilderId: string,
) => {
    let isPrePollingDay = dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS;
    let isElectionDay = dayTypeId === DAY_TYPE_ID.ELECTION_DAY;
    return serviceInstance.get('/locations/availablelist', {params: {
            // lng: longitude,
            // lat: latitude,
            prepoll: dayTypeId === DAY_TYPE_ID.BOTH || isPrePollingDay,
            elecday: dayTypeId === DAY_TYPE_ID.BOTH || isElectionDay,
            maxRecord: size,
            nbid: nationBuilderId,
        }}).then((response: any) => response?.status === StatusCodes.OK ? response.data : []);
}

export const LocationService = {
    getAvailableLocations
}
