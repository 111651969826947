import Header from '../Header';
import Footer from '../Footer';
import { ReactNode, useEffect, useState } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';
import { NumberUtil } from '../../utils/Number.util';
import { ContentLayoutContext } from './contexts/ContentLayout.context';

type ContentLayoutProps = {
    title: string;
    stepNumber?: number;
    children: ReactNode;
    footerContent: ReactNode;
    footerClass?: string;
}

const ContentLayout = ({title, stepNumber, children, footerContent, footerClass = ""}: ContentLayoutProps) => {
    const {height: windowHeight} = useWindowSize();
    const [headerRef, { height: headerHeight }] = useElementSize();
    const [footerRef, { height: footerHeight }] = useElementSize();
    const [bodyHeight, setBodyHeight] = useState<number>(0);

    useEffect(() => {
        setBodyHeight(windowHeight - (headerHeight + footerHeight));
    }, [windowHeight, headerHeight, footerHeight]);

    return (
        <ContentLayoutContext.Provider value={{bodyHeight, setBodyHeight}}>
            <section className="bg-white md:overflow-hidden">
                <Header containerRef={headerRef}
                        stepNumber={stepNumber}
                        stepTitle={title} />
                <div className="grow overflow-y-auto" style={{height: `${NumberUtil.convertPxToRem(bodyHeight)}rem`}}>
                    {children}
                </div>
                <Footer containerRef={footerRef}
                        className={footerClass}>
                    {footerContent}
                </Footer>
            </section>
        </ContentLayoutContext.Provider>
    );
}

export default ContentLayout;
