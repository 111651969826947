import ScheduleTile from './components/ScheduleTile';
import { LocationSchedule } from '../../../../../../models/LocationSchedule.model';
import { Schedule } from '../../../../../../models/Schedule.model';
import { LocationUtil } from '../../../../../../utils/Location.util';

type ScheduleListProps = {
    containerRef?: any;
    locationSchedule: LocationSchedule;
}

const ScheduleList = ({containerRef, locationSchedule}: ScheduleListProps) => {
    const schedules: Schedule[] = locationSchedule?.schedules ?? [];
    return (
        <div ref={containerRef} className="mb-6 last:mb-0">
            <div className="text-[#67777F] font-bold pb-3">
                {LocationUtil.removePPVCFromLocationName(locationSchedule.locationName ?? '')}
            </div>
            <div>
                <>
                    {schedules?.length === 0 && (
                        <div className="text-center">No data found</div>
                    )}
                    {schedules?.map((schedule: Schedule) => (
                        <ScheduleTile key={schedule.scheduleId} schedule={schedule} />
                    ))}
                </>
            </div>
        </div>
    );
}

export default ScheduleList;
