import ScheduleSection from '../ScheduleSection';
import RightDrawer from '../../../../partials/RightDrawer';
import ShiftList from '../ShiftList';
import { ShiftMobileContext } from '../../contexts/ShiftMobile.context';
import { useShiftContext } from '../../contexts/Shift.context';
import NextButton from '../../../../partials/NextButton';
import { useElementSize } from 'usehooks-ts';

type ShiftSelectionMobileProps = {
    isOpenShiftList: boolean;
    setOpenShiftList: (isOpenShiftList: boolean) => void;
}

const ShiftSelectionMobile = ({isOpenShiftList, setOpenShiftList}: ShiftSelectionMobileProps) => {
    const {setSelectedScheduleId} = useShiftContext();
    const [saveShiftButtonRef, { height: saveShiftButtonHeight }] = useElementSize();

    const closeShiftList = () => {
        setOpenShiftList(false);
        setSelectedScheduleId('');
    };

    return (
        <ShiftMobileContext.Provider value={{
            isOpenShiftList,
            setOpenShiftList,
            saveShiftButtonHeight,
        }}>
            <div className="p-4">
                <ScheduleSection />
                <RightDrawer isOpened={isOpenShiftList}
                             setClose={closeShiftList}
                             title="Select your desired location">
                    <div>
                        <ShiftList />
                        <NextButton containerRef={saveShiftButtonRef} className="w-full text-white bg-primary-orange" title="Save Shift" onClick={closeShiftList} />
                    </div>
                </RightDrawer>
            </div>
        </ShiftMobileContext.Provider>
    );
}

export default ShiftSelectionMobile;
