import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSubmissionContext } from '../../contexts/Submission.context';
import { DAY_TYPE_ID } from '../../constants/DayTypeId.constant';
import { useEffect, useState } from 'react';
import { LocalStorageUtil } from '../../utils/LocalStorage.util';
import { ContactService } from '../../services/Contact.service';
import { Contact } from '../../models/Contact.model';
import { Submission } from '../../models/Submission.model';
import { ROUTE_PATH } from '../../constants/RoutePath.constant';
import NationBuilderErrorDialog from '../../partials/NationBuilderErrorDialog';
import ExistingSubmissionWithoutCodeErrorDialog from "../../partials/ExistingSubmissionWithoutCodeErrorDialog";
import { Spinner } from 'flowbite-react';

const WelcomePageV3 = () => {
    const {nationBuilderId} = useParams();
    const navigate = useNavigate();
    const {submission, setSubmission, setSubmissionCode, setEditingShifts} = useSubmissionContext();
    const [searchParams] = useSearchParams();
    const dayTypeId: DAY_TYPE_ID = searchParams.get('daytype') as DAY_TYPE_ID ?? DAY_TYPE_ID.BOTH;
    const code = searchParams.get('code') || ""
    const [isContactError, setContactError] = useState(false);
    const [isContactLoading, setContactLoading] = useState(true);
    const [existingSubmissionError, setExistingSubmissionError] = useState(false)

    const saveEditingShifts = (submission: any) => {
        let shiftIds: string[] = []
        const schedules = submission.schedules || []

        for(let i = 0 ; i < schedules.length; i++){
            const schedule = schedules[i];
            const standardShiftIds = schedule.standardShifts.filter((shift: any)=>shift).map((shift: any)=>shift._id)
            shiftIds = shiftIds.concat(standardShiftIds)
        }

        setEditingShifts(shiftIds)

    }

    useEffect(() => {
        LocalStorageUtil.clearData();
    }, []);

    useEffect(() => {
        if (nationBuilderId) {
            ContactService.getContactByNationalBuilderId(nationBuilderId, dayTypeId, code).then((contact: Contact) => {
                setContactLoading(false);
                saveEditingShifts(contact?.submission || {})
                const storedSubmissionId = contact?.submission?._id ?? '';
                const updatedSubmission: Submission = (storedSubmissionId && contact?.submission)
                    ? {
                        ...contact.submission,
                        dayTypeId, // even user edit, show both shift as default for them
                        storedSchedules: contact?.submission?.schedules,
                    }
                    : {
                        ...submission,
                        nationBuilderId,
                        dayTypeId,
                    };
                LocalStorageUtil.setStoredSubmission(updatedSubmission);
                setSubmission(updatedSubmission);
                if(contact.allowSubmit){
                    setSubmissionCode(code);
                }else{
                    setExistingSubmissionError(true)
                    return;
                }

                if (storedSubmissionId) {
                    navigate(`/${ROUTE_PATH.SUMMARY}?isReviewing=true`);
                } else {
                    navigate(`/${dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? ROUTE_PATH.LOCATION_SELECTION : ROUTE_PATH.BOOTH_CAPTAIN_AGREEMENT}`);
                }
            }).catch((error: any) => {
                console.log(error)
                setContactLoading(false);
                setContactError(true);
                console.error('WelcomePage > ContactService.getContactByNationalBuilderId has an error', error);
            });
        }
    }, [nationBuilderId]);

    return (
        <>
            <NationBuilderErrorDialog
                message="It’s a little busy right now. Please refresh the page or try again later."
                isOpen={isContactError}
                setOpen={(value)=>{setContactError(value)}}
                onYesButtonClick={()=>{window.location.reload()}}
                onNoButtonClick={() => {
                }} />
            <ExistingSubmissionWithoutCodeErrorDialog
                message="To change your shifts, please use the link that was sent to you via email and SMS."
                isOpen={existingSubmissionError}
                setOpen={(value)=>{setExistingSubmissionError(value)}}
                onYesButtonClick={()=>{ window.location.href = 'https://www.volunteerforno.com.au'}}
                />
            <div className="h-screen flex flex-col justify-center text-center"><Spinner size="xl" aria-label="Data is loading" /></div>
        </>
    );
}

export default WelcomePageV3;
