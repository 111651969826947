import ScheduleList from './components/ScheduleList';
import styles from './index.module.scss';
import { useShiftContext } from '../../contexts/Shift.context';
import { LocationSchedule } from '../../../../models/LocationSchedule.model';
import { Spinner } from 'flowbite-react';
import { useContentLayoutContext } from '../../../../partials/ContentLayout/contexts/ContentLayout.context';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../../../constants/Breakpoint.constant';
import { NumberUtil } from '../../../../utils/Number.util';
import { useElementSize } from 'usehooks-ts';
import { useEffect, useState } from 'react';

const BODY_PADDING_Y = 32;
const SCHEDULE_lIST_MARGIN_BOTTOM = 24;
const MD_SCHEDULE_SECTION_MARGIN_Y: number = 24 * 2;
const SM_SCHEDULE_SECTION_MARGIN_Y: number = 16 * 2;

const ScheduleSection = () => {
    const {locationSchedules, isScheduleLoading} = useShiftContext();
    const {bodyHeight} = useContentLayoutContext();
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const isSmScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.MD });
    const [firstScheduleListRef, { height: firstScheduleListHeight }] = useElementSize();
    const [secondScheduleListRef, { height: secondScheduleListHeight }] = useElementSize();
    const scheduleListRefs = [firstScheduleListRef, secondScheduleListRef];
    const [isDisplayMobileScrollbarPadding, setDisplayMobileScrollbarPadding] = useState<boolean>(false);

    useEffect(() => {
        const actualListHeight: number = firstScheduleListHeight + secondScheduleListHeight + (locationSchedules?.length > 1 ? SCHEDULE_lIST_MARGIN_BOTTOM : 0);
        setDisplayMobileScrollbarPadding(actualListHeight > bodyHeight);
    }, [firstScheduleListHeight, secondScheduleListHeight]);

    return (
        <div style={{height: `${NumberUtil.convertPxToRem(bodyHeight - (isXsScreen ? BODY_PADDING_Y : isSmScreen ? SM_SCHEDULE_SECTION_MARGIN_Y : MD_SCHEDULE_SECTION_MARGIN_Y))}rem`}} className={`basis-[55%] overflow-y-auto ${isDisplayMobileScrollbarPadding ? 'pr-3' : ''} ${styles.scheduleSectionContent} sm:my-4 sm:ml-4 sm:pr-6 md:my-6 md:pl-10 md:pr-6`}>
            {isScheduleLoading && (
                <div className="h-full flex items-center justify-center flex-col">
                    <Spinner
                        color="failure"
                        aria-label="Schedule list is loading"
                    />
                </div>
            )}
            {!isScheduleLoading && locationSchedules.map((locationSchedule: LocationSchedule, index: number) => (
                <ScheduleList containerRef={scheduleListRefs[index]} key={locationSchedule.locationId} locationSchedule={locationSchedule} />
            ))}
        </div>
    );
}

export default ScheduleSection;
