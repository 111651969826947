import { useEffect, useState } from 'react';
import ContentLayout from '../../partials/ContentLayout';
import { LocationService } from '../../services/Location.service';
import { DAY_TYPE_ID } from '../../constants/DayTypeId.constant';
import { LocationInfo } from '../../models/LocationInfo.model';
import { LocationContext } from './contexts/Location.context';
import { useSubmissionContext } from '../../contexts/Submission.context';
import { DEFAULT_NUMBER } from '../../constants/Number.constant';
import NextButton from '../../partials/NextButton';
import { ROUTE_PATH } from '../../constants/RoutePath.constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LocationSelectionMobile from './components/LocationSelectionMobile';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINT } from '../../constants/Breakpoint.constant';
import LocationSelectionMedium from './components/LocationSelectionMedium';
import { Submission } from '../../models/Submission.model';
import { LocalStorageUtil } from '../../utils/LocalStorage.util';
import BackButton from '../../partials/BackButton';
import _ from "lodash"

const getDefaultDisplayedLocationSize = (selectedDateTypeId: DAY_TYPE_ID, isXsScreen: boolean) => {
    return isXsScreen ? DEFAULT_NUMBER.MOBILE_LOCATION_SIZE :
        selectedDateTypeId === DAY_TYPE_ID.BOTH
            ? DEFAULT_NUMBER.BOTH_DAY_TYPE_LOCATION_SIZE
            : DEFAULT_NUMBER.SINGLE_DAY_TYPE_LOCATION_SIZE
}

const LocationSelectionPage = () => {
    const [prePollingLocationInfos, setPrePollingLocationInfos] = useState<LocationInfo[]>([]);
    const [electionLocationInfos, setElectionLocationInfos] = useState<LocationInfo[]>([]);
    const [selectedPrePollingLocationId, setSelectedPrePollingLocationId] = useState<string>('');
    const [selectedElectionLocationId, setSelectedElectionLocationId] = useState<string>('');
    const [isPrePollingLocationLoading, setPrePollingLocationLoading] = useState<boolean>(true);
    const [isElectionLocationLoading, setElectionLocationLoading] = useState<boolean>(true);
    const {submission, setSubmission} = useSubmissionContext();
    const [selectedDateTypeId, setSelectedDateTypeId] = useState<DAY_TYPE_ID>(submission?.dayTypeId ?? submission?.selectedDayTypeId ?? DAY_TYPE_ID.NONE);
    const isPrepollDateTypeIdSelected: boolean = selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS;
    // const [userLongitude, setUserLongitude] = useState<number>(submission.userLongitude ?? DEFAULT_NUMBER.LONGITUDE);
    // const [userLatitude, setUserLatitude] = useState<number>(submission.userLatitude ?? DEFAULT_NUMBER.LATITUDE);
    const [nationBuilderId, setNationBuilderId] = useState<string>(submission?.nationBuilderId ?? submission?.nationbuilder_id ?? '');
    const isXsScreen: boolean = useMediaQuery({ maxWidth: BREAKPOINT.SM });
    const defaultLocationSize = getDefaultDisplayedLocationSize(selectedDateTypeId, isXsScreen);
    const [prePollingLocationSize, setPrePollingLocationSize] = useState<number>(defaultLocationSize);
    const [electionLocationSize, setElectionLocationSize] = useState<number>(defaultLocationSize);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isEditingSelectedLocations: boolean = searchParams.get('isEditing') === 'true';

    useEffect(() => {
        const storeSubmission: Submission = LocalStorageUtil.getStoredSubmission();
        if (storeSubmission?.nationBuilderId || storeSubmission?.nationbuilder_id) {
            setSubmission(storeSubmission);
        }
    }, []);

    useEffect(() => {
        setNationBuilderId(submission?.nationBuilderId ?? submission?.nationbuilder_id ?? '');
        setSelectedDateTypeId(submission?.dayTypeId ?? submission?.selectedDayTypeId ?? DAY_TYPE_ID.NONE);
        // setUserLatitude(submission?.userLatitude ?? DEFAULT_NUMBER.LATITUDE);
        // setUserLongitude(submission?.userLongitude ?? DEFAULT_NUMBER.LONGITUDE);
        setSelectedPrePollingLocationId(submission?.selectedPrePollingLocationInfo?.locationID ?? '');
        setSelectedElectionLocationId(submission?.selectedElectionLocationInfo?.locationID ?? '');
    }, [submission]);

    useEffect(() => {
        getInitLocations();
    }, [selectedDateTypeId]);

    useEffect(() => {
        if (prePollingLocationSize !== defaultLocationSize
            && (selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS || selectedDateTypeId === DAY_TYPE_ID.BOTH)) {
            getLocationForSingleDayType(DAY_TYPE_ID.PRE_POLLING_DAYS, prePollingLocationSize);
        }
    }, [prePollingLocationSize]);

    useEffect(() => {
        if (electionLocationSize !== defaultLocationSize
            && (selectedDateTypeId === DAY_TYPE_ID.ELECTION_DAY || selectedDateTypeId === DAY_TYPE_ID.BOTH)) {
            getLocationForSingleDayType(DAY_TYPE_ID.ELECTION_DAY, electionLocationSize);
        }
    }, [electionLocationSize]);

    useEffect(() => {
        if (isEditingSelectedLocations) {
            autoSelectLocationForEditing(DAY_TYPE_ID.PRE_POLLING_DAYS, prePollingLocationInfos);
        }
    }, [prePollingLocationInfos]);

    useEffect(() => {
        if (isEditingSelectedLocations) {
            autoSelectLocationForEditing(DAY_TYPE_ID.ELECTION_DAY, electionLocationInfos);
        }
    }, [electionLocationInfos]);

    const getInitLocations = () => {
        if (selectedDateTypeId !== DAY_TYPE_ID.NONE) {
            if (selectedDateTypeId === DAY_TYPE_ID.BOTH) {
                getLocationForBothDayTypes();
            } else {
                const initLocationSize = selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS ? prePollingLocationSize : electionLocationSize;
                if (selectedDateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
                    setElectionLocationLoading(false);
                } else if (selectedDateTypeId === DAY_TYPE_ID.ELECTION_DAY) {
                    setPrePollingLocationLoading(false);
                }
                getLocationForSingleDayType(selectedDateTypeId, initLocationSize);
            }
        }
    }

    const getLocationForBothDayTypes = () => {
        getLocationForSingleDayType(DAY_TYPE_ID.PRE_POLLING_DAYS, prePollingLocationSize);
        getLocationForSingleDayType(DAY_TYPE_ID.ELECTION_DAY, electionLocationSize);
    }

    const getLocationForSingleDayType = (dateTypeId: DAY_TYPE_ID, locationSize: number) => {
        if (dateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
            setPrePollingLocationLoading(true);
        } else if (dateTypeId === DAY_TYPE_ID.ELECTION_DAY){
            setElectionLocationLoading(true);
        }
        getLocationByDayType(dateTypeId, locationSize).then((locationInfos: LocationInfo[]) => {
            if (dateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
                // Get previous selected locations
                const selectedLocation = (submission.registeredLocations || []).filter((location)=>location.eventType === DAY_TYPE_ID.PRE_POLLING_DAYS)
                // Calculate size to slice
                const sizeToSlice = locationInfos.length || selectedLocation.length;
                setPrePollingLocationInfos((_.uniqBy(selectedLocation.concat(locationInfos), "locationID")).slice(0,sizeToSlice));
                setPrePollingLocationLoading(false);
            } else if (dateTypeId === DAY_TYPE_ID.ELECTION_DAY) {

                // Get previous selected locations
                const selectedLocation = (submission.registeredLocations || []).filter((location)=>location.eventType === DAY_TYPE_ID.ELECTION_DAY)
                // Calculate size to slice
                const sizeToSlice = locationInfos.length || selectedLocation.length;
                    setElectionLocationInfos((_.uniqBy(selectedLocation.concat(locationInfos), "locationID")).slice(0,sizeToSlice));
                setElectionLocationLoading(false);
            }
        });
    }

    const autoSelectLocationForEditing = (dateTypeId: DAY_TYPE_ID, locationInfos: LocationInfo[]) => {
        if (locationInfos?.length > 0) {
            if (dateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
                const selectedPrepollingLocations = (submission.registeredLocations || []).filter((location)=> location.eventType === DAY_TYPE_ID.PRE_POLLING_DAYS)
                const isSelected = _.intersectionBy(locationInfos, selectedPrepollingLocations, "locationID")

                if(isSelected.length > 0){
                    setSelectedPrePollingLocationId(isSelected[0].locationID);
                }

                // const selectedPrePollingLocationInfo = submission?.selectedPrePollingLocationInfo;
                // if (selectedPrePollingLocationInfo?.locationID) {
                //     const isSelectedLocationInfoAlreadyLoaded: boolean = !!locationInfos?.find(
                //         (locationInfo: LocationInfo) => locationInfo.locationID === selectedPrePollingLocationInfo.locationID
                //     );
                //     if (isSelectedLocationInfoAlreadyLoaded) {
                //         setSelectedPrePollingLocationId(selectedPrePollingLocationInfo.locationID);
                //     }
                // }
            } else if (dateTypeId === DAY_TYPE_ID.ELECTION_DAY) {
                const selectedPollingLocations = (submission.registeredLocations || []).filter((location)=> location.eventType === DAY_TYPE_ID.ELECTION_DAY)
                const isSelected = _.intersectionBy(locationInfos, selectedPollingLocations, "locationID")

                if(isSelected.length > 0){
                    setSelectedElectionLocationId(isSelected[0].locationID);
                }
                // const selectedElectionLocationInfo = submission?.selectedElectionLocationInfo;
                // if (selectedElectionLocationInfo?.locationID) {
                //     const isSelectedLocationInfoAlreadyLoaded: boolean = !!locationInfos?.find(
                //         (locationInfo: LocationInfo) => locationInfo.locationID === selectedElectionLocationInfo.locationID
                //     );
                //     if (isSelectedLocationInfoAlreadyLoaded) {
                //         setSelectedElectionLocationId(selectedElectionLocationInfo.locationID);
                //     }
                // }
            }
        }
    }

    const getLocationByDayType = (dateTypeId: DAY_TYPE_ID, locationSize: number) => {
        return LocationService.getAvailableLocations(
            // userLongitude,
            // userLatitude,
            dateTypeId,
            locationSize,
            nationBuilderId,
        ).catch((error: any) => {
            if (dateTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
                setPrePollingLocationLoading(false);
            } else if (dateTypeId === DAY_TYPE_ID.ELECTION_DAY) {
                setElectionLocationLoading(false);
            }
            console.error('LocationSelectionPage > LocationService.getAvailableLocations has an error', error);
            return [];
        });
    }

    const handleViewMoreLocationClick = (dayTypeId: DAY_TYPE_ID, newLocationSize: number) => {
        if (dayTypeId === DAY_TYPE_ID.PRE_POLLING_DAYS) {
            setPrePollingLocationSize(newLocationSize);
        } else if (dayTypeId === DAY_TYPE_ID.ELECTION_DAY) {
            setElectionLocationSize(newLocationSize);
        }
    }

    const handleContinueButtonClick = () => {
        const updatedSubmission: Submission = {
            ...submission,
            selectedPrePollingLocationInfo: getLocationInfoById(selectedPrePollingLocationId, true),
            selectedElectionLocationInfo: getLocationInfoById(selectedElectionLocationId),
        };
        LocalStorageUtil.setStoredSubmission(updatedSubmission);
        setSubmission(updatedSubmission);
        navigate(`/${ROUTE_PATH.SHIFT_SELECTION}${isEditingSelectedLocations ? '?isEditing=true' : ''}`);
    }

    const getLocationInfoById = (locationId: string, isPrePolling?: boolean): LocationInfo => {
        const locationInfos = isPrePolling ? prePollingLocationInfos : electionLocationInfos;
        return locationInfos.find((info: LocationInfo) => info.locationID === locationId) ?? {} as LocationInfo;
    }

    const handleBackButtonClick = () => {
        navigate(`/${ROUTE_PATH.BOOTH_CAPTAIN_AGREEMENT}`);
    }

    return (
        <ContentLayout title="Select Locations you’d like to volunteer at"
                       stepNumber={isPrepollDateTypeIdSelected ? 1 : 2}
                       footerContent={(
                           <div className={`flex items-center ${isPrepollDateTypeIdSelected ? 'justify-end' : 'justify-between'} w-full`}>
                               {!isPrepollDateTypeIdSelected && (
                                   <BackButton onClick={handleBackButtonClick} />
                               )}
                               <NextButton className={`flex items-center font-extrabold uppercase text-sm bg-primary-orange p-3 text-white justify-between md:basis-auto ${isPrepollDateTypeIdSelected ? 'basis-full' : 'basis-[49%]'}`}
                                           title="Continue"
                                           isDisable={!selectedPrePollingLocationId && !selectedElectionLocationId}
                                           onClick={handleContinueButtonClick} />
                           </div>
                       )}>
            <LocationContext.Provider value= {{
                selectedPrePollingLocationId,
                setSelectedPrePollingLocationId,
                selectedElectionLocationId,
                setSelectedElectionLocationId,
                isPrePollingLocationLoading,
                setPrePollingLocationLoading,
                isElectionLocationLoading,
                setElectionLocationLoading,
            }}>
                <div className="sm:hidden">
                    <LocationSelectionMobile defaultLocationSize={defaultLocationSize}
                                             prePollingLocationInfos={prePollingLocationInfos}
                                             electionLocationInfos={electionLocationInfos}
                                             prePollingLocationSize={prePollingLocationSize}
                                             electionLocationSize={electionLocationSize}
                                             onViewMoreLocationClick={handleViewMoreLocationClick} />
                </div>
                <div className="hidden sm:block">
                    <LocationSelectionMedium selectedDateTypeId={selectedDateTypeId}
                                             prePollingLocationInfos={prePollingLocationInfos}
                                             electionLocationInfos={electionLocationInfos}
                                             prePollingLocationSize={prePollingLocationSize}
                                             electionLocationSize={electionLocationSize}
                                             onViewMoreLocationClick={handleViewMoreLocationClick} />
                </div>
            </LocationContext.Provider>
        </ContentLayout>
    );
}

export default LocationSelectionPage;
