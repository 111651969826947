import { createContext, useContext } from 'react';

export type ContentLayoutProp = {
    bodyHeight: number,
    setBodyHeight: (bodyHeight: number) => void,
}

export const ContentLayoutContext = createContext<ContentLayoutProp>({
    bodyHeight: 0,
    setBodyHeight: () => {},
})

export const useContentLayoutContext = () => useContext(ContentLayoutContext);
