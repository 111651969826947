import { CheckIcon } from '@heroicons/react/24/solid';

const SummarySuccessTile = () => {
    return (
        <div className="flex mx-4 mb-4 rounded-xl bg-[#05B23629] p-5 items-center">
            <div className="bg-[#05B236] p-3 rounded-[50%]"><CheckIcon className="w-5 h-5 text-white" /></div>
            <div className="text-[#05B236] ml-3">
                <span className="font-bold">Shift(s) confirmed!</span> A confirmation SMS has been sent to you.
            </div>
        </div>
    )
}

export default SummarySuccessTile;
